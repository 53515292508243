import { useMemo } from 'react'

import { RL1_ADDRESS } from '@/constants/blockchain'
import RL1 from '@/contracts/RL1'
import { useCustomContractRead, useCustomContractReadBalance } from '@/hooks/useCustomContract'
import { l1NativeWagmiConfig } from '@/plugins/auth/config'
import { parseError } from '@/utils/helpers'
import { processBalance } from '@/utils/utils'

import { useChainValues } from './useChain'

const PERIOD_LENGTH = 7884000 // 1 quarter
const DAY_LENGTH = 86400 // 1 day
const NUM_DAYS = PERIOD_LENGTH / DAY_LENGTH

const useRL1 = () => {
  // Get block number
  const { blockNumber } = useChainValues(l1NativeWagmiConfig.id)

  // Supply
  const {
    value: supply,
    isLoading: isLoadingSupply,
    error: errorSupply,
  } = useCustomContractReadBalance(
    {
      chainId: l1NativeWagmiConfig.id,
      address: RL1_ADDRESS,
      args: [],
      abi: RL1,
      functionName: 'totalSupply',
    },
    blockNumber
  )

  // Deposited
  const {
    value: deposited,
    isLoading: isLoadingDeposited,
    error: errorDeposited,
  } = useCustomContractReadBalance(
    {
      chainId: l1NativeWagmiConfig.id,
      address: RL1_ADDRESS,
      args: [],
      abi: RL1,
      functionName: 'totalDeposited',
    },
    blockNumber
  )

  // Withdrawn
  const {
    value: withdrawn,
    isLoading: isLoadingWithdrawn,
    error: errorWithdrawn,
  } = useCustomContractReadBalance(
    {
      chainId: l1NativeWagmiConfig.id,
      address: RL1_ADDRESS,
      args: [RL1_ADDRESS],
      abi: RL1,
      functionName: 'totalWithdrawn',
    },
    blockNumber
  )

  // Unlockable_10k
  const {
    value: unlockable10k,
    isLoading: isLoadingUnlockable10k,
    error: errorUnlockable10k,
  } = useCustomContractRead(
    {
      chainId: l1NativeWagmiConfig.id,
      address: RL1_ADDRESS,
      args: [],
      abi: RL1,
      functionName: 'unlockable_10k',
    },
    blockNumber
  )

  // Unlock rate length
  const {
    value: unlockRateLen,
    isLoading: isLoadingUnlockRateLen,
    error: errorUnlockRateLen,
  } = useCustomContractRead(
    {
      chainId: l1NativeWagmiConfig.id,
      address: RL1_ADDRESS,
      args: [],
      abi: RL1,
      functionName: 'rateLength',
    },
    blockNumber
  )

  // Index to get
  const rateIndex = useMemo(() => (unlockRateLen ? unlockRateLen - 1 : 0), [unlockRateLen])

  // Unlock rate
  const {
    value: unlockRateValue,
    isLoading: isLoadingUnlockRate,
    error: errorUnlockRate,
  } = useCustomContractRead(
    {
      chainId: l1NativeWagmiConfig.id,
      address: RL1_ADDRESS,
      args: [BigInt(rateIndex)],
      abi: RL1,
      functionName: 'unlockRates',
    },
    blockNumber
  )

  // Compute rate per day
  const unlockRate = useMemo(
    () => (unlockRateValue ? unlockRateValue / 10000 / NUM_DAYS : 0),
    [unlockRateValue]
  )

  // Compute unlockable RL1
  const unlockable = useMemo(() => {
    if (deposited && withdrawn && unlockable10k) {
      // Compute how much has been unlocked
      const unlocked = parseFloat(deposited.value.toString()) * (unlockable10k / 1000000)
      // Subtract how much has been withdrawn
      const remaining = unlocked - parseFloat(withdrawn.value.toString())
      return processBalance(BigInt(remaining))
    }
    return processBalance(BigInt(0))
  }, [deposited, withdrawn, unlockable10k])

  // Period start
  const {
    value: periodStart,
    isLoading: isLoadingPeriodStart,
    error: errorPeriodStart,
  } = useCustomContractRead(
    {
      chainId: l1NativeWagmiConfig.id,
      address: RL1_ADDRESS,
      args: [],
      abi: RL1,
      functionName: 'periodStart',
    },
    blockNumber
  )

  // Current period
  const {
    value: currentPeriod,
    isLoading: isLoadingCurrentPeriod,
    error: errorCurrentPeriod,
  } = useCustomContractRead(
    {
      chainId: l1NativeWagmiConfig.id,
      address: RL1_ADDRESS,
      args: [],
      abi: RL1,
      functionName: 'currentPeriod',
    },
    blockNumber
  )

  // NOTE: remove this once contracts have been fully tested
  // and just use hardcoded value for seconds per period
  // Get seconds per period
  const { value: secondsPerPeriod } = useCustomContractRead(
    {
      chainId: l1NativeWagmiConfig.id,
      address: RL1_ADDRESS,
      args: [],
      abi: RL1,
      functionName: 'secondsPerPeriod',
    },
    blockNumber
  )

  // Compute current period start timestamp
  const currentPeriodStart = useMemo(() => {
    if (periodStart && currentPeriod !== undefined && secondsPerPeriod) {
      return Number(periodStart) + currentPeriod * secondsPerPeriod
    }
    return 0
  }, [periodStart, currentPeriod, secondsPerPeriod])

  // Compute current period end timestamp
  const currentPeriodEnd = useMemo(() => {
    if (periodStart && currentPeriod !== undefined && secondsPerPeriod) {
      return Number(periodStart) + (currentPeriod + 1) * secondsPerPeriod
    }
    return 0
  }, [periodStart, currentPeriod, secondsPerPeriod])

  // Proof tokens length
  const {
    value: proofTokensLen,
    isLoading: isLoadingProofTokensLen,
    error: errorProofTokensLen,
  } = useCustomContractRead(
    {
      chainId: l1NativeWagmiConfig.id,
      address: RL1_ADDRESS,
      args: [],
      abi: RL1,
      functionName: 'proofTokensLength',
    },
    blockNumber
  )

  // Tokens index to get
  const tokensIndex = useMemo(() => (proofTokensLen ? proofTokensLen - 1 : 0), [proofTokensLen])

  // RL1 being minted for total duration of current period
  const {
    value: minted,
    isLoading: isLoadingMinted,
    error: errorMinted,
  } = useCustomContractReadBalance(
    {
      chainId: l1NativeWagmiConfig.id,
      address: RL1_ADDRESS,
      args: [BigInt(tokensIndex)],
      abi: RL1,
      functionName: 'claimableProofTokens',
    },
    blockNumber
  )

  // RL1 minted for total of previous periods
  const {
    value: mintedPast,
    isLoading: isLoadingMintedPast,
    error: errorMintedPast,
  } = useCustomContractReadBalance(
    {
      chainId: l1NativeWagmiConfig.id,
      address: RL1_ADDRESS,
      args: [BigInt(tokensIndex > 1 ? tokensIndex - 1 : 0)],
      abi: RL1,
      functionName: 'cumClaimableProofTokens',
    },
    blockNumber
  )

  // Compute supply left to mint
  const supplyLeft = useMemo(() => {
    // On first period, supply left is deposited
    if (tokensIndex === 0) {
      return deposited
    }
    if (deposited && mintedPast) {
      return processBalance(deposited.value - mintedPast.value)
    }
    return processBalance(BigInt(0))
  }, [deposited, tokensIndex, mintedPast])

  // RL1 being minted per day
  const mintedPerDay = useMemo(() => {
    if (minted) {
      return processBalance(BigInt(parseFloat(minted.value.toString()) / NUM_DAYS))
    }
    return processBalance(BigInt(0))
  }, [minted])

  // Loading
  const isLoading = useMemo(
    () =>
      isLoadingSupply ||
      isLoadingDeposited ||
      isLoadingWithdrawn ||
      isLoadingUnlockable10k ||
      isLoadingUnlockRateLen ||
      isLoadingUnlockRate ||
      isLoadingPeriodStart ||
      isLoadingCurrentPeriod ||
      isLoadingProofTokensLen ||
      isLoadingMinted ||
      isLoadingMintedPast,
    [
      isLoadingSupply,
      isLoadingDeposited,
      isLoadingWithdrawn,
      isLoadingUnlockable10k,
      isLoadingUnlockRateLen,
      isLoadingUnlockRate,
      isLoadingPeriodStart,
      isLoadingCurrentPeriod,
      isLoadingProofTokensLen,
      isLoadingMinted,
      isLoadingMintedPast,
    ]
  )

  // Error
  const errors = [
    errorSupply,
    errorDeposited,
    errorWithdrawn,
    errorUnlockable10k,
    errorUnlockRateLen,
    errorUnlockRate,
    errorPeriodStart,
    errorCurrentPeriod,
    errorProofTokensLen,
    errorMinted,
    errorMintedPast,
  ]
  const error = useMemo(() => parseError(errors), errors)

  return {
    supply,
    supplyLeft,
    unlockable,
    unlockRate,
    currentPeriod,
    currentPeriodStart,
    currentPeriodEnd,
    minted,
    mintedPerDay,
    isLoading,
    error: {
      call: `rl1.${error.call}`,
      msg: error.msg,
      isError: error.isError,
    },
  }
}

export default useRL1
