import { useDisclosure } from '@mantine/hooks'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Loading from '@/components/Loading'
import Send from '@/components/Transfer/Send'
import { switchChain } from '@/plugins/auth/utils'

import ModalSkeleton from '../ModalSkeleton'
import ConfirmTransaction from '../Send/ConfirmTransaction'

interface SendModalProps {
  close: () => void
}

const SendModal: React.FC<SendModalProps> = ({ close }) => {
  const { t } = useTranslation()
  const [okToClose, setOkToClose] = useState<boolean>(true)
  const [openedAlert, { open: openAlert, close: closeAlert }] = useDisclosure(false)

  // modal on cancel / close make sure to be in Lamina1 network
  const [openedSwitchNetwork, { open: openSwitchNetwork, close: closeSwitchNetwork }] =
    useDisclosure(false)

  const handleDoubleConfirmation = useCallback(() => {
    setOkToClose(true)
    closeAlert()
    close()
  }, [setOkToClose, closeAlert, close])

  const tryChainSwitch = useCallback(async () => {
    openSwitchNetwork()
    try {
      await switchChain(false, false)
      closeSwitchNetwork()
    } catch (err: any) {
      console.error(err)
      if (err.message.includes('User rejected')) {
        setTimeout(async () => {
          await tryChainSwitch()
        }, 1000)
      }
    }
  }, [closeSwitchNetwork])

  const handleCancel = useCallback(async () => {
    // if (window.ethereum) {
    //   const currentChainId = await window.ethereum.request({ method: 'eth_chainId' })
    //   if (currentChainId !== l1NativeConfig.chainIdHex) {
    //     await tryChainSwitch()
    //   } else {
    //     close()
    //   }
    // } else {
    // if no ethereum provider, assume we are using Web3Auth and close the modal
    // await tryChainSwitch()
    // close()
    // }
    if (!okToClose) {
      openAlert()
    } else {
      await tryChainSwitch()
      close()
    }
  }, [close, tryChainSwitch, okToClose, openAlert])

  return (
    <ModalSkeleton
      opened
      onClose={handleCancel}
      title={t('components.wallet.send', 'Send')}
      allowModalCloseOutside={false}
    >
      <>
        <ModalSkeleton
          opened={openedAlert}
          onClose={closeAlert}
          size="420px"
          title={t('components.send.confirmExitTxNotComplete', 'Warning: Nonrefundable')}
        >
          <ConfirmTransaction
            close={closeAlert}
            confirm={handleDoubleConfirmation}
            message={t(
              'components.send.confirmExitTxNotCompleteMessage',
              'You have already paid a nonrefundable bridge fee. If you close this window before completing your bridge transaction you will lose the fee. Are you sure you want to exit? Confirm if you want to exit.'
            )}
          />
        </ModalSkeleton>
        {openedSwitchNetwork ? (
          <Loading
            title={t(
              'components.wallet.switchNetwork.modalTitle',
              'Switching Networks... To proceed you need to connect to Lamina1 Network'
            )}
          />
        ) : (
          <Send close={handleCancel} setOkToClose={setOkToClose} />
        )}
      </>
    </ModalSkeleton>
  )
}

export default SendModal
