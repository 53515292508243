import { switchChain as switchNetwork } from '@wagmi/core'

import {
  avalancheConfig,
  // avalancheMetamaskConfig,
  getWagmiConfig,
  l1IdSubnetConfig,
  // l1IdSubnetMetamaskConfig,
  l1NativeConfig,
  // l1NativeMetamaskConfig,
  MetamaskChainConfig,
} from './config'

const addChainToMetaMask = async (chainInfo: MetamaskChainConfig): Promise<boolean | void> => {
  const { ethereum } = window as any

  if (!ethereum || !ethereum.isMetaMask) {
    throw new Error('MetaMask not installed')
  }

  try {
    await ethereum.request({
      method: 'wallet_addEthereumChain',
      params: [chainInfo],
    })
    Promise.resolve(true)
  } catch (error: any) {
    throw new Error(`Failed to add custom chain: ${error.message}`)
  }
}

/* -------------------------------------------------------------------------- */
/*                              Export Functions                              */
/* -------------------------------------------------------------------------- */
export const switchChainInMetaMask = async (
  chainInfo: MetamaskChainConfig
): Promise<boolean | void> => {
  const { ethereum } = window as any

  if (!ethereum || !ethereum.isMetaMask) {
    throw new Error('MetaMask not installed')
  }
  try {
    await ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: chainInfo.chainId }],
    })
  } catch (switchError: any) {
    console.warn('Failed to switch chain, attempting to add:', switchError.message)
    try {
      await addChainToMetaMask(chainInfo)
    } catch (error: any) {
      throw new Error(`Failed to add chain: ${error.message}`)
    }
  }
}

export const addTokenToMetamask = async (
  tokenAddress: string,
  tokenSymbol: string,
  tokenIcon: string
) => {
  const { ethereum } = window as any

  if (!ethereum || !ethereum.isMetaMask) {
    throw new Error('MetaMask not installed')
  }

  try {
    const wasAdded = await ethereum.request({
      method: 'wallet_watchAsset',
      params: {
        type: 'ERC20',
        options: {
          address: tokenAddress,
          symbol: tokenSymbol,
          decimals: 18,
          image: tokenIcon,
        },
      },
    })
    return wasAdded
  } catch (error: any) {
    throw new Error(`Failed to add token to MetaMask: ${error.message}`)
  }
}

export const switchChain = async (subnet: boolean, avax: boolean) => {
  // const metamaskChain = subnet
  //   ? avax
  //     ? avalancheMetamaskConfig
  //     : l1IdSubnetMetamaskConfig
  //   : avax
  //     ? avalancheMetamaskConfig
  //     : l1NativeMetamaskConfig
  const web3AuthChain = subnet
    ? avax
      ? avalancheConfig
      : l1IdSubnetConfig
    : avax
      ? avalancheConfig
      : l1NativeConfig

  await switchNetwork(getWagmiConfig(), { chainId: web3AuthChain.chainId })
  // const connectedAdapterName = localStorage.getItem('Web3Auth-cachedAdapter')
  // if (connectedAdapterName === 'metamask') {
  //   console.log('Switching chain on MetaMask')
  //   try {
  //     await switchChainInMetaMask(metamaskChain)
  //   } catch (error: any) {
  //     throw new Error(`Failed to switch chain on MetaMask: ${error.message}`)
  //   }
  // } else {
  //   console.log('Switching chain on Web3Auth')
  //   try {
  //     await switchNetwork(getWagmiConfig(), {
  //       chainId: web3AuthChain.chainId,
  //     })
  //   } catch (error: any) {
  //     throw new Error(`Failed to switch chain on Web3Auth: ${error.message}`)
  //   }
  // }
}
