/* -------------------------------------------------------------------------- */
/*                              L1 Native Config                              */
/* -------------------------------------------------------------------------- */
export const NATIVE_CHAIN_NAME = process.env.NATIVE_CHAIN_NAME || 'Lamina1 Network'
export const NATIVE_CHAIN_TICKER = process.env.NATIVE_CHAIN_TICKER || 'L1'
export const NATIVE_CHAIN_REWARD_TICKER = process.env.NATIVE_CHAIN_REWARD_TICKER || 'RL1'
export const NATIVE_CHAIN_LAUNCH_TICKER = process.env.NATIVE_CHAIN_LAUNCH_TICKER || 'LL1'
export const NATIVE_CHAIN_ID = Number(process.env.NATIVE_CHAIN_ID) || 43112
export const NATIVE_CHAIN_RPC = process.env.NATIVE_CHAIN_RPC || 'http://localhost:9650/ext/bc/C/rpc'
export const NATIVE_CHAIN_EXPLORER_URL =
  process.env.NATIVE_CHAIN_EXPLORER_URL || 'https://explorer.lamina1.com'

/* -------------------------------------------------------------------------- */
/*                             Avax Network Config                            */
/* -------------------------------------------------------------------------- */
export const AVAX_CHAIN_NAME = process.env.AVAX_CHAIN_NAME || 'Avalanche Network'
export const AVAX_CHAIN_TICKER = 'AVAX'
export const AVAX_CHAIN_ID = Number(process.env.AVAX_CHAIN_ID) || 43112
export const AVAX_CHAIN_RPC = process.env.AVAX_CHAIN_RPC || 'http://localhost:9650/ext/bc/C/rpc'
export const AVAX_EXPLORER_URL =
  process.env.AVAX_CHAIN_EXPLORER_URL || 'https://explorer.avax.network'

/* -------------------------------------------------------------------------- */
/*                           Identity Subnet Config                           */
/* -------------------------------------------------------------------------- */
export const IDENTITY_SUBNET_NAME = process.env.IDENTITY_SUBNET_NAME || 'Lamina1 Identity Subnet'
export const IDENTITY_SUBNET_TICKER = process.env.IDENTITY_SUBNET_TICKER || 'L1ID'
export const IDENTITY_SUBNET_ID = Number(process.env.IDENTITY_SUBNET_ID) || 12345
export const IDENTITY_SUBNET_RPC =
  process.env.IDENTITY_SUBNET_RPC || 'http://localhost:9650/ext/bc/identitytest/rpc'

/* -------------------------------------------------------------------------- */
/*                          Smart Contract Addresses                          */
/* -------------------------------------------------------------------------- */

// L1 Identity > L1NS
export const CONTRACT_REGISTRY_ADDRESS = (process.env.CONTRACT_REGISTRY_ADDRESS ||
  '0x52C84043CD9c865236f11d9Fc9F56aa003c1f922') as `0x${string}`
export const CONTRACT_CONTROLLER_ADDRESS = (process.env.CONTRACT_CONTROLLER_ADDRESS ||
  '0xa4DfF80B4a1D748BF28BC4A271eD834689Ea3407') as `0x${string}`
export const CONTRACT_RESOLVER_ADDRESS = (process.env.CONTRACT_RESOLVER_ADDRESS ||
  '0x8B3BC4270BE2abbB25BC04717830bd1Cc493a461') as `0x${string}`
export const CONTRACT_REVERSE_ADDRESS = (process.env.CONTRACT_REVERSE_ADDRESS ||
  '0x5DB9A7629912EBF95876228C24A848de0bfB43A9') as `0x${string}`

// L1 > L1NS
export const CONTRACT_COLLECTOR_ADDRESS = (process.env.CONTRACT_COLLECTOR_ADDRESS ||
  '0x52C84043CD9c865236f11d9Fc9F56aa003c1f922') as `0x${string}`

// L1 > Spaces
export const CONTRACT_SPACES_REGISTRY_ADDRESS = (process.env.CONTRACT_SPACES_REGISTRY_ADDRESS ||
  '0xA4cD3b0Eb6E5Ab5d8CE4065BcCD70040ADAB1F00') as `0x${string}`

// Bridges L1 <-> AVAX

// L1 NativeTokenHome on Lamina1 Subnet
export const BRIDGE_L1_NATIVE_HOME_ADDRESS = (process.env.BRIDGE_L1_NATIVE_HOME_ADDRESS ||
  '0x43b12939dD24f3f73A9786Fc18bE6c023433c512') as `0x${string}`

// AVAX ERC20TokenRemote on Lamina1 Subnet
export const BRIDGE_AVAX_ERC20_REMOTE_ADDRESS = (process.env.BRIDGE_AVAX_ERC20_REMOTE_ADDRESS ||
  '0x302f7217243bD560C439834491a57191d59Bc106') as `0x${string}`

// L1 ERC20TokenRemote on AVAX
export const BRIDGE_L1_ERC20_REMOTE_ADDRESS = (process.env.BRIDGE_L1_ERC20_REMOTE_ADDRESS ||
  '0x43b12939dD24f3f73A9786Fc18bE6c023433c512') as `0x${string}`

// AVAX NativeTokenHome on AVAX
export const BRIDGE_AVAX_NATIVE_HOME_ADDRESS = (process.env.BRIDGE_AVAX_NATIVE_HOME_ADDRESS ||
  '0x302f7217243bD560C439834491a57191d59Bc106') as `0x${string}`

// Blockchain IDs
export const AVAX_CHAIN_BLOCKCHAIN_ID =
  process.env.AVAX_CHAIN_BLOCKCHAIN_ID ||
  '0x31233cae135e3974afa396e90f465aa28027de5f97f729238c310d2ed2f71902'

export const LAMINA1_CHAIN_BLOCKCHAIN_ID =
  process.env.LAMINA1_CHAIN_BLOCKCHAIN_ID ||
  '0xb02f57272dd5c17171484c5547f575a6bbee898ee339d02bc6a24d7309ff0ba5'

// Bridge Relayer Address
export const BRIDGE_RELAYER_ADDRESS = (process.env.BRIDGE_RELAYER_ADDRESS ||
  '0xF83059ceB9A390b693468A3Eaf3c4c4108A24CEe') as `0x${string}`

// Bridge Relayer Fee
export const BRIDGE_RELAYER_FEE = process.env.BRIDGE_RELAYER_FEE || '0.001'

// Staking
export const LL1_ADDRESS = (process.env.LL1_ADDRESS ||
  '0xB8a934dcb74d0E3d1DF6Bce0faC12cD8B18801eD') as `0x${string}`
export const RL1_ADDRESS = (process.env.RL1_ADDRESS ||
  '0xe17bDC68168d07c1776c362d596adaa5d52A1De7') as `0x${string}`
export const STAKE_ADDRESS = (process.env.STAKE_ADDRESS ||
  '0xF5f1f185cF359dC48469e410Aeb6983cD4DC5812') as `0x${string}`
export const REWARDS_ADDRESS = (process.env.REWARDS_ADDRESS ||
  '0xa1E47689f396fED7d18D797d9D31D727d2c0d483') as `0x${string}`

/* -------------------------------------------------------------------------- */
/*                               Known Addresses                              */
/* -------------------------------------------------------------------------- */
// OMAC / Creators fund
export const CREATOR_FUND_ADDRESS = (process.env.CREATOR_FUND_ADDRESS ||
  '0x3C44CdDdB6a900fa2b585dd299e03d12FA4293BC') as `0x${string}`

// Ecosystem Fund
export const ECOSYSTEM_FUND_ADDRESS = (process.env.ECOSYSTEM_FUND_ADDRESS ||
  '0x90F79bf6EB2c4f870365E785982E1f101E93b906') as `0x${string}`

// Foundation multisig
export const FOUNDATION_MSIG_ADDRESS = (process.env.FOUNDATION_MSIG_ADDRESS ||
  '0x8db97C7cEcE249c2b98bDC0226Cc4C2A57BF52FC') as `0x${string}`

// Airdrop Contract
export const AIRDROP_CONTRACT_ADDRESS = (process.env.AIRDROP_CONTRACT_ADDRESS ||
  '0xc2A6ff1b31C69ae7c7DEAD49Ba44692AC55dFde7') as `0x${string}`

// Airdrop multisig
export const AIRDROP_MSIG_ADDRESS = (process.env.AIRDROP_MSIG_ADDRESS ||
  '0x90ed011AA935E29c49CB85DB8fB7C31fE9EF32aC') as `0x${string}`

// Rewards Recipients
export const REWARDS_RECIPIENTS_LIST = [STAKE_ADDRESS, CREATOR_FUND_ADDRESS, ECOSYSTEM_FUND_ADDRESS]

// Studio ArtWork Template
export const CONTRACT_STUDIO_TEMPLATE_ADDRESS = (process.env.CONTRACT_STUDIO_TEMPLATE_ADDRESS ||
  '0x5FbDB2315678afecb367f032d93F642f64180aa3') as `0x${string}`

export const FAUCET_ADDRESS = (process.env.FAUCET_ADDRESS ||
  '0xd2797A601c962D641b2adB8Dcd69C7FAc28C3f04') as `0x${string}`

/* -------------------------------------------------------------------------- */
/*                          LAMINA1 Community Awards                          */
/* -------------------------------------------------------------------------- */

// LAMINA1 EAP Pass
export const LAMINA1_PASS_ADDRESS = (process.env.LAMINA1_PASS_ADDRESS ||
  '0x1cf29Efd16400F9F4995a0A2261c9c891a556Af9') as `0x${string}`

// LAMINA1 Gold Pass
export const LAMINA1_GOLD_PASS_ADDRESS = (process.env.LAMINA1_GOLD_PASS_ADDRESS ||
  '0x1cf29Efd16400F9F4995a0A2261c9c891a556Af9') as `0x${string}`

// LAMINA1 Platinum Pass
export const LAMINA1_PLATINUM_PASS_ADDRESS = (process.env.LAMINA1_PLATINUM_PASS_ADDRESS ||
  '0x1cf29Efd16400F9F4995a0A2261c9c891a556Af9') as `0x${string}`

// LAMINA1 Awards
export const LAMINA1_AWARDS_ADDRESS = (process.env.LAMINA1_AWARDS_ADDRESS ||
  '0x1cf29Efd16400F9F4995a0A2261c9c891a556Af9') as `0x${string}`

/* -------------------------------------------------------------------------- */
/*                            Other Known Addresses                           */
/* -------------------------------------------------------------------------- */

// Default Addresses
export const BLANK_ADDRESS = '0x0000000000000000000000000000000000000000'
export const BURN_ADDRESS = '0x0100000000000000000000000000000000000000'
export const NO_PROFILE_ADDRESSES = [
  BLANK_ADDRESS,
  CONTRACT_CONTROLLER_ADDRESS,
  CONTRACT_RESOLVER_ADDRESS,
  CONTRACT_REVERSE_ADDRESS,
  CONTRACT_COLLECTOR_ADDRESS,
  CONTRACT_SPACES_REGISTRY_ADDRESS,
  BRIDGE_L1_NATIVE_HOME_ADDRESS,
  BRIDGE_AVAX_ERC20_REMOTE_ADDRESS,
  BRIDGE_L1_ERC20_REMOTE_ADDRESS,
  BRIDGE_AVAX_NATIVE_HOME_ADDRESS,
  LL1_ADDRESS,
  RL1_ADDRESS,
  STAKE_ADDRESS,
  REWARDS_ADDRESS,
  CREATOR_FUND_ADDRESS,
  FAUCET_ADDRESS,
  AIRDROP_CONTRACT_ADDRESS,
]

/* -------------------------------------------------------------------------- */
/*                                    Bots                                    */
/* -------------------------------------------------------------------------- */
export const STUDIO_BOT = process.env.STUDIO_BOT_URL || 'https://localhost:3000/metaverse'
export const ID_BOT = process.env.IDENTITY_BOT_URL || 'http://localhost:12345'
export const STAKING_BOT = process.env.STAKING_BOT_URL || 'http://localhost:34567'
export const CMC_BOT = process.env.CMC_BOT_URL || 'http://localhost:45678'

/* -------------------------------------------------------------------------- */
/*                               Default Values                               */
/* -------------------------------------------------------------------------- */
export const MIN_STAKE_DAYS = 1
export const MIN_STAKE_AMOUNT = 0.1
export const FREE_MIN_BALANCE = 0.005

/* -------------------------------------------------------------------------- */
/*                                  Features                                  */
/* -------------------------------------------------------------------------- */

export const EARNED_COLLECTIONS_IDS = [
  `"${LAMINA1_PASS_ADDRESS}"`,
  `"${LAMINA1_GOLD_PASS_ADDRESS}"`,
  `"${LAMINA1_PLATINUM_PASS_ADDRESS}"`,
  `"${LAMINA1_AWARDS_ADDRESS}"`,
]

// Profile Pages - Feature Gatting
export const profileHideOwnedItems = process.env.PROFILE_HIDE_OWNED_ITEMS === 'true' || false
export const profileHasEarnedItems = process.env.PROFILE_HAS_EARNED_ITEMS === 'true' || false

// Hub Pages - Feature Gatting
export const explorePageActive = process.env.EXPLORE_PAGE_ACTIVE === 'true' || false
export const feedPageActive = process.env.FEED_PAGE_ACTIVE === 'true' || false
