import { AuthAdapter, PrivateKeyProvider } from '@web3auth/auth-adapter'
import { ChainNamespaceType, CustomChainConfig, IAdapter, WALLET_ADAPTERS } from '@web3auth/base'
import { getInjectedAdapters } from '@web3auth/default-evm-adapter'
import { EthereumPrivateKeyProvider } from '@web3auth/ethereum-provider'
import { Web3Auth, Web3AuthOptions } from '@web3auth/modal'
import { LANGUAGE_TYPE, LANGUAGES, OPENLOGIN_NETWORK_TYPE } from '@web3auth/openlogin-adapter'
import { Web3AuthConnector, Web3AuthConnectorParams } from '@web3auth/web3auth-wagmi-connector'
import { defineChain, http, toHex } from 'viem'
import { createConfig } from 'wagmi'

// import { metaMask } from 'wagmi/connectors'
import {
  AVAX_CHAIN_ID,
  AVAX_CHAIN_NAME,
  AVAX_CHAIN_RPC,
  AVAX_EXPLORER_URL,
  IDENTITY_SUBNET_ID,
  IDENTITY_SUBNET_NAME,
  IDENTITY_SUBNET_RPC,
  IDENTITY_SUBNET_TICKER,
  NATIVE_CHAIN_EXPLORER_URL,
  NATIVE_CHAIN_ID,
  NATIVE_CHAIN_NAME,
  NATIVE_CHAIN_RPC,
  NATIVE_CHAIN_TICKER,
} from '@/constants/blockchain'
import { lazySingleton } from '@/utils/helpers'

interface NativeCurrency {
  name: string
  symbol: string
  decimals: number
}

export interface MetamaskChainConfig {
  chainId: string
  chainName: string
  nativeCurrency: NativeCurrency
  rpcUrls: string[]
  blockExplorerUrls?: string[]
  iconUrls?: string[]
}

// interface WagmiChainConfig extends Omit<Omit<MetamaskChainConfig, 'rpcUrls'>, 'nativeCurrency'> {
export interface WagmiChainConfig {
  name: string
  network: string
  id: number
  rpcUrls: {
    public: {
      http: string[]
    }
    default: {
      http: string[]
    }
  }
  blockExplorers: {
    default: {
      name: string
      url: string
    }
  }
  nativeCurrency: NativeCurrency
}

export interface Web3AuthChainConfig extends CustomChainConfig {}

/* -------------------------------------------------------------------------- */
/*                       General Network Configurations                       */
/* -------------------------------------------------------------------------- */
export const l1NativeConfig = {
  name: NATIVE_CHAIN_NAME,
  network: NATIVE_CHAIN_NAME.toLocaleLowerCase().replace(' ', '_'),
  chainId: NATIVE_CHAIN_ID,
  chainIdHex: toHex(NATIVE_CHAIN_ID),
  nativeCurrency: {
    name: 'Lamina',
    symbol: NATIVE_CHAIN_TICKER,
    decimals: 18,
  },
  chainNamespace: 'eip155',
  rpcUrl: NATIVE_CHAIN_RPC,
  blockExplorerUrl: NATIVE_CHAIN_EXPLORER_URL,
}

export const l1IdSubnetConfig = {
  name: IDENTITY_SUBNET_NAME,
  network: IDENTITY_SUBNET_NAME.toLocaleLowerCase().replace(' ', '_'),
  chainId: IDENTITY_SUBNET_ID,
  chainIdHex: toHex(IDENTITY_SUBNET_ID),
  nativeCurrency: {
    name: 'L1Identity',
    symbol: IDENTITY_SUBNET_TICKER,
    decimals: 18,
  },
  chainNamespace: 'eip155',
  rpcUrl: IDENTITY_SUBNET_RPC,
  blockExplorerUrl: NATIVE_CHAIN_EXPLORER_URL,
}

export const avalancheConfig = {
  name: AVAX_CHAIN_NAME,
  network: AVAX_CHAIN_NAME.toLocaleLowerCase().replace(' ', '_'),
  chainId: AVAX_CHAIN_ID,
  chainIdHex: toHex(AVAX_CHAIN_ID),
  nativeCurrency: {
    name: 'Avalanche',
    symbol: 'AVAX',
    decimals: 18,
  },
  chainNamespace: 'eip155',
  rpcUrl: AVAX_CHAIN_RPC,
  blockExplorerUrl: AVAX_EXPLORER_URL,
}

/* -------------------------------------------------------------------------- */
/*                            Metamask Chain Config                           */
/* -------------------------------------------------------------------------- */
export const l1NativeMetamaskConfig: MetamaskChainConfig = {
  chainId: l1NativeConfig.chainIdHex,
  chainName: l1NativeConfig.name,
  nativeCurrency: l1NativeConfig.nativeCurrency,
  rpcUrls: [l1NativeConfig.rpcUrl],
  blockExplorerUrls: [l1NativeConfig.blockExplorerUrl],
  iconUrls: [],
}

export const l1IdSubnetMetamaskConfig: MetamaskChainConfig = {
  chainId: l1IdSubnetConfig.chainIdHex,
  chainName: l1IdSubnetConfig.name,
  nativeCurrency: l1IdSubnetConfig.nativeCurrency,
  rpcUrls: [l1IdSubnetConfig.rpcUrl],
  blockExplorerUrls: [l1IdSubnetConfig.blockExplorerUrl],
  iconUrls: [],
}

export const avalancheMetamaskConfig: MetamaskChainConfig = {
  chainId: avalancheConfig.chainIdHex,
  chainName: avalancheConfig.name,
  nativeCurrency: avalancheConfig.nativeCurrency,
  rpcUrls: [avalancheConfig.rpcUrl],
  blockExplorerUrls: [avalancheConfig.blockExplorerUrl],
  iconUrls: [],
}

/* -------------------------------------------------------------------------- */
/*                            Web3Auth Chain Config                           */
/* -------------------------------------------------------------------------- */
export const l1NativeWeb3AuthConfig: Web3AuthChainConfig = {
  displayName: l1NativeConfig.name,
  chainId: l1NativeConfig.chainIdHex,
  chainNamespace: l1NativeConfig.chainNamespace as ChainNamespaceType,
  rpcTarget: l1NativeConfig.rpcUrl,
  blockExplorerUrl: l1NativeConfig.blockExplorerUrl,
  ticker: l1NativeConfig.nativeCurrency.symbol,
  tickerName: l1NativeConfig.nativeCurrency.name,
  decimals: l1NativeConfig.nativeCurrency.decimals,
  logo: 'https://uploads-ssl.webflow.com/63fe332d7b9ae4159d741e55/640b9679f321ab6b6af536fd_favicon.jpg',
}

/* export const l1IdSubnetWeb3AuthConfig: Web3AuthChainConfig = {
  displayName: l1IdSubnetConfig.name,
  chainId: l1IdSubnetConfig.chainIdHex,
  chainNamespace: l1IdSubnetConfig.chainNamespace as ChainNamespaceType,
  rpcTarget: l1IdSubnetConfig.rpcUrl,
  blockExplorer: l1IdSubnetConfig.blockExplorerUrl,
  ticker: l1IdSubnetConfig.nativeCurrency.symbol,
  tickerName: l1IdSubnetConfig.nativeCurrency.name,
  decimals: l1IdSubnetConfig.nativeCurrency.decimals,
} */

/* -------------------------------------------------------------------------- */
/*                             Wagmi Chain Config                             */
/* -------------------------------------------------------------------------- */
export const l1NativeWagmiConfig: WagmiChainConfig = defineChain({
  name: l1NativeConfig.name,
  network: l1NativeConfig.network,
  id: l1NativeConfig.chainId,
  rpcUrls: {
    public: {
      http: [l1NativeConfig.rpcUrl],
    },
    default: {
      http: [l1NativeConfig.rpcUrl],
    },
  },
  blockExplorers: {
    default: {
      name: 'Lamina1 Explorer',
      url: l1NativeConfig.blockExplorerUrl,
    },
  },
  nativeCurrency: l1NativeConfig.nativeCurrency,
  // ...l1NativeMetamaskConfigWithoutElements,
})

export const l1IdSubnetWagmiConfig: WagmiChainConfig = defineChain({
  name: l1IdSubnetConfig.name,
  network: l1IdSubnetConfig.network,
  id: l1IdSubnetConfig.chainId,
  rpcUrls: {
    public: {
      http: [l1IdSubnetConfig.rpcUrl],
    },
    default: {
      http: [l1IdSubnetConfig.rpcUrl],
    },
  },
  blockExplorers: {
    default: {
      name: 'Lamina1 Explorer',
      url: l1IdSubnetConfig.blockExplorerUrl,
    },
  },
  nativeCurrency: l1IdSubnetConfig.nativeCurrency,
  // ...l1IdSubnetMetamaskConfigWithoutElements,
})

export const avalancheWagmiConfig: WagmiChainConfig = defineChain({
  name: avalancheConfig.name,
  network: avalancheConfig.network,
  id: avalancheConfig.chainId,
  rpcUrls: {
    public: {
      http: [avalancheConfig.rpcUrl],
    },
    default: {
      http: [avalancheConfig.rpcUrl],
    },
  },
  blockExplorers: {
    default: {
      name: 'Avalanche Explorer',
      url: avalancheConfig.blockExplorerUrl,
    },
  },
  nativeCurrency: avalancheConfig.nativeCurrency,
  // ...avalancheMetamaskConfigWithoutElements,
})

export const chains = [l1NativeWagmiConfig, l1IdSubnetWagmiConfig, avalancheWagmiConfig]

function isNetworkType(value: any): value is OPENLOGIN_NETWORK_TYPE {
  return ['mainnet', 'cyan', 'testnet'].includes(value)
}

const web3AuthNetwork: OPENLOGIN_NETWORK_TYPE = isNetworkType(process.env.WEB3AUTH_NETWORK)
  ? process.env.WEB3AUTH_NETWORK
  : 'testnet'
// Web3 Auth Config
const WEB3AUTH_CLIENT_ID =
  process.env.WEB3AUTH_CLIENT_ID ||
  'BH0Cp1N7-v87NV1_4HmkQCuRuvDP5X5321etOkf46Rk1Y7_G4X_7LRH5TzG3S9K2GyH3jiIkVMGQLKg8RBY_nZM'
const initModalParams = {
  modalConfig: {
    // Disable WalletConnect
    [WALLET_ADAPTERS.WALLET_CONNECT_V2]: {
      label: 'wallet_connect',
      showOnModal: false,
    },
    // Disable Torus
    [WALLET_ADAPTERS.TORUS_EVM]: {
      label: 'Torus Wallet',
      showOnModal: false,
    },
    [WALLET_ADAPTERS.AUTH]: {
      label: 'auth',
      loginMethods: {
        // Disable some social logins
        apple: {
          name: 'apple',
          showOnModal: true,
        },
        twitch: {
          name: 'twitch',
          showOnModal: false,
        },
        facebook: {
          name: 'facebook',
          showOnModal: false,
        },
        farcaster: {
          name: 'farcaster',
          showOnModal: false,
        },
        reddit: {
          name: 'reddit',
          showOnModal: false,
        },
        line: {
          name: 'line',
          showOnModal: false,
        },
        github: {
          name: 'github',
          showOnModal: true,
        },
        wechat: {
          name: 'wechat',
          showOnModal: false,
        },
        kakao: {
          name: 'kakao',
          showOnModal: false,
        },
        linkedin: {
          name: 'linkedin',
          showOnModal: false,
        },
        weibo: {
          name: 'weibo',
          showOnModal: false,
        },
        // Disable sms_passwordless
        sms_passwordless: {
          name: 'sms_passwordless',
          showOnModal: false,
        },
      },
    },
  },
} satisfies Omit<Web3AuthConnectorParams, 'web3AuthInstance'>

function getDefaultLanguage(): LANGUAGE_TYPE | undefined {
  // default to english
  localStorage.setItem('i18nextLng', 'en-US')
  const i18nextLocale = localStorage.getItem('i18nextLng') ?? undefined
  if (!i18nextLocale || i18nextLocale.length < 2) return undefined

  const i18nextLng = i18nextLocale.substring(0, 2) as LANGUAGE_TYPE
  if (!(Object.values(LANGUAGES) as LANGUAGE_TYPE[]).includes(i18nextLng)) return undefined

  return i18nextLng
}

export const getWeb3AuthInstance = lazySingleton(() => {
  const privateKeyProvider = new EthereumPrivateKeyProvider({
    config: { chainConfig: l1NativeWeb3AuthConfig },
  }) as unknown as PrivateKeyProvider
  const web3AuthOptions: Web3AuthOptions = {
    privateKeyProvider,
    clientId: WEB3AUTH_CLIENT_ID,
    web3AuthNetwork,
    uiConfig: {
      appName: 'LAMINA1',
      logoLight:
        'https://uploads-ssl.webflow.com/63fe332d7b9ae4159d741e55/640b9679f321ab6b6af536fd_favicon.jpg',
      logoDark:
        'https://uploads-ssl.webflow.com/63fe332d7b9ae4159d741e55/640b9679f321ab6b6af536fd_favicon.jpg',
      // If the user's browser settings prefer dark theme, but then the user changes to light theme, the auth modal will still be in dark theme because the "auto" option always gets the setting from the browser. To fix this, the user-defined theme needs to be used here instead of "auto". A HOC needs to be created to wrap the WagmiConfig provider and replace the "auto" option with the user-defined theme. Same thing would happen if the browser's setting is light theme and the user changes to dark theme.
      mode: 'auto',
      loginMethodsOrder: ['discord', 'google', 'twitter', 'apple', 'github'],
      defaultLanguage: getDefaultLanguage(),
      loginGridCol: 2, // 2 | 3
      primaryButton: 'externalLogin', // "externalLogin" | "socialLogin" | "emailLogin",
    },
    sessionTime: 60 * 60 * 24 * 7, // 7 days
    enableLogging: false,
  }
  const web3Auth = new Web3Auth(web3AuthOptions)
  const loginAdapter = new AuthAdapter({
    loginSettings: {
      mfaLevel: 'optional',
    },
    adapterSettings: {
      network: web3AuthNetwork,
      uxMode: 'redirect',
      redirectUrl: `${window.location.origin}/loggedIn`,
      replaceUrlOnRedirect: true,
      whiteLabel: {
        appName: 'Lamina1',
        logoDark:
          'https://uploads-ssl.webflow.com/63fe332d7b9ae4159d741e55/640b9679f321ab6b6af536fd_favicon.jpg',
        logoLight:
          'https://uploads-ssl.webflow.com/63fe332d7b9ae4159d741e55/640b9679f321ab6b6af536fd_favicon.jpg',
        defaultLanguage: 'en', // en, de, ja, ko, zh, es, fr, pt, nl
        // If the user's browser settings prefer dark theme, but then the user changes to light theme, the auth modal will still be in dark theme because the "auto" option always gets the setting from the browser. To fix this, the user-defined theme needs to be used here instead of "auto". A HOC needs to be created to wrap the WagmiConfig provider and replace the "auto" option with the user-defined theme. Same thing would happen if the browser's setting is light theme and the user changes to dark theme.
        mode: 'auto', // whether to enable dark mode. defaultValue: false
      },
    },
    privateKeyProvider,
  })

  // const metamaskAdapter = new MetamaskAdapter({
  //   clientId: WEB3AUTH_CLIENT_ID,
  //   sessionTime: 60 * 60 * 24 * 7, // 7 days
  //   web3AuthNetwork,
  //   chainConfig: l1NativeWeb3AuthConfig,
  // })
  // web3Auth.configureAdapter(metamaskAdapter)

  // Add Adapters
  const injectedAdapters = getInjectedAdapters({ options: web3AuthOptions })
  injectedAdapters.forEach((adapter: IAdapter<unknown>) => {
    // copy adapters to a new object and change their injextedProvider.isMetamask boolean to false if the name key is not 'metamask' or a similar version of that string
    if (adapter.name === 'metamask') {
      web3Auth.configureAdapter(adapter)
    }
  })

  // const defaultExternalAdapters = getDefaultExternalAdapters({ options: web3AuthOptions })
  // defaultExternalAdapters.forEach((adapter: IAdapter<unknown>) => {
  //   web3Auth.configureAdapter(adapter)
  // })
  web3Auth.configureAdapter(loginAdapter)

  return web3Auth
})

export const getWagmiConfig = lazySingleton(() =>
  createConfig({
    chains: [l1NativeWagmiConfig, l1IdSubnetWagmiConfig, avalancheWagmiConfig],
    transports: {
      [l1NativeWagmiConfig.id]: http(l1NativeConfig.rpcUrl),
      [l1IdSubnetWagmiConfig.id]: http(l1IdSubnetConfig.rpcUrl),
      [avalancheWagmiConfig.id]: http(avalancheConfig.rpcUrl),
    },
    connectors: [
      Web3AuthConnector({
        web3AuthInstance: getWeb3AuthInstance(),
        ...initModalParams,
      }),
      // metaMask(),
      // injected({ shimDisconnect: true }),
    ],
  })
)

export const getInjectedConnector = lazySingleton(() => {
  const config = getWagmiConfig()
  const connector = config.connectors.find(c => c.name === 'Injected')
  if (!connector) {
    throw new Error('Injected connector not found')
  }
  return connector
})

export const getWeb3AuthConnector = lazySingleton(() => {
  const config = getWagmiConfig()
  const connector = config.connectors.find(c => c.name === 'Web3Auth')
  if (!connector) {
    throw new Error('Web3Auth connector not found')
  }
  return connector
})
