import { Stack, Text, Title, useMantineTheme } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TransactionReceipt } from 'viem'

import RootButton from '@/components/Buttons/RootButton'
import CurrencyInput from '@/components/CurrencyInput'
import DisplayUsername from '@/components/DisplayUsername'
import ErrorMessage from '@/components/ErrorMessage'
import Loading from '@/components/Loading'
import { ProfileMetadata } from '@/components/Profile/metadata'
import { displayImage } from '@/components/Profile/ProfileHeader'
import OnTransfer from '@/components/Transfer/OnTransfer'
import TransactionSummary from '@/components/Transfer/TransactionSummary'
import { useChainFunctions } from '@/hooks/useChain'
import useEstimatedFee from '@/hooks/useEstimatedFee'
import useLocalStorage from '@/hooks/useLocalStorage'
import { getDefaultProfileImage } from '@/plugins/auth/AuthProvider/effects/functions'
import { l1NativeWagmiConfig } from '@/plugins/auth/config'
import { StoredTheme } from '@/utils/utils'

interface ITipProfileProps {
  onCancel: () => void
  profileAddress: string
  profileUsername: string
  profile: ProfileMetadata | undefined
}

const TipProfile: React.FC<ITipProfileProps> = ({
  onCancel,
  profileAddress,
  profileUsername,
  profile,
}) => {
  const theme = useMantineTheme()
  const { t } = useTranslation()
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`)

  const { sendTransfer } = useChainFunctions(l1NativeWagmiConfig)

  // fees
  const { estimatedFee } = useEstimatedFee()

  // Send L1
  const [amount, setAmount] = useState({ value: '', touched: false, error: '' })
  const [txHash, setTxHash] = useState<string | null>(null)
  const [sendingTx, setSendingTx] = useState<boolean | null>(null)
  const [errorSending, setErrorSending] = useState<string>('')

  // default profile images
  const [userTheme] = useLocalStorage<StoredTheme>('userTheme')
  const defaultPfp = useMemo(() => getDefaultProfileImage(userTheme?.isLightMode), [userTheme])

  const sendTransaction = useCallback(async () => {
    if (!profileAddress) {
      setTxHash(null)
      return
    }
    setSendingTx(true)
    try {
      const receipt = (await sendTransfer(profileAddress, amount.value)) as TransactionReceipt
      if (receipt?.status !== 'success') {
        throw new Error('Transaction failed')
      }
      setTxHash(receipt.transactionHash)
    } catch (err: any) {
      setSendingTx(false)
      throw new Error(err)
    }
  }, [sendTransfer, amount.value])

  const submitTransaction = useCallback(async () => {
    try {
      await sendTransaction()
    } catch (error: any) {
      console.error('An error occurred:', error)
      if (!error.message.includes('User rejected the request.')) {
        setErrorSending(error.message)
      }
    }
  }, [sendTransaction])

  const handleTransactionConfirmation = useCallback(() => {
    setTxHash(null)
    setSendingTx(false)
    setAmount({ value: '', touched: false, error: '' })
    onCancel()
  }, [setTxHash, setSendingTx])

  return (
    <>
      {errorSending && (
        <ErrorMessage
          message={t('components.send.errorSending', 'Error sending transaction')}
          details={errorSending}
        />
      )}
      {sendingTx && (
        <Stack>
          {txHash ? (
            <OnTransfer
              hash={txHash}
              chainId={l1NativeWagmiConfig.id}
              success={handleTransactionConfirmation}
            />
          ) : (
            <Loading title={t('components.send.sendingTx', 'Sending Transaction')} />
          )}
        </Stack>
      )}
      {!errorSending && !sendingTx && (
        <Stack>
          <Text>
            {t(
              'components.profile.modal.tipMessage',
              'Support L1 creators. Send a tip to share your appreciation for this person’s work.'
            )}
          </Text>
          <Stack
            style={{ flexDirection: 'row', gap: 20, alignItems: 'center', marginBottom: '30px' }}
          >
            <img
              src={displayImage(defaultPfp, profile?.image || '')}
              width={48}
              height={48}
              style={{ borderRadius: '8px' }}
              alt="profile"
            />
            <Stack style={{ gap: '2px' }}>
              {profile?.name !== '' ? (
                <Title order={4}>{profile?.name}</Title>
              ) : (
                <Title order={4}>{t('components.profile.noName', 'No Name')}</Title>
              )}
              <DisplayUsername
                truncate={isMobile}
                address={profileAddress}
                username={profileUsername}
              />
            </Stack>
          </Stack>

          <>
            <CurrencyInput estimatedFee={estimatedFee} amount={amount} setAmount={setAmount} />
            <TransactionSummary amount={amount.value} />
            <Stack style={{ flexDirection: 'row', gap: 10, marginTop: '40px' }}>
              <RootButton style1={false} expand secondary onClick={onCancel}>
                {t('buttons.cancel', 'Cancel')}
              </RootButton>
              <RootButton
                style1={false}
                expand
                disabled={sendingTx || !profileAddress || !amount.touched || amount.error !== ''}
                onClick={submitTransaction}
              >
                {t('buttons.send', 'Send')}
              </RootButton>
            </Stack>
          </>
        </Stack>
      )}
    </>
  )
}
export default TipProfile
