import { Divider, Stack, Text, Title, useMantineTheme } from '@mantine/core'
import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import RootButton from '../../Buttons/RootButton'
import SpecialInputTile from '../../SpecialInputTile'

export const StringSizeRule: React.FC<{
  countChars: number
  minChars: number
  maxChars: number
}> = ({ countChars, minChars, maxChars }) => {
  const theme = useMantineTheme()
  return (
    <Stack style={{ display: 'flex', flexDirection: 'row', gap: 0 }}>
      <Text
        style={{
          color:
            countChars > maxChars || countChars < minChars
              ? theme.colors.notification[0]
              : theme.colors.title[0],
        }}
      >
        {countChars}
      </Text>
      <Text
        style={{
          color: countChars > maxChars ? theme.colors.notification[0] : theme.colors.textAndIcon[0],
        }}
      >
        /{maxChars}
      </Text>
    </Stack>
  )
}

interface DescriptionProps {
  title: string
  setTitle: (title: string) => void
  description: string
  setDescription: (description: string) => void
  prevStep: () => void
  nextStep: () => void
  metadata: any
  saveDraft: () => void
}

const Description: React.FC<DescriptionProps> = ({
  title,
  setTitle,
  description,
  setDescription,
  prevStep,
  nextStep,
  metadata,
  saveDraft,
}) => {
  const theme = useMantineTheme()

  const templateData = JSON.parse(JSON.stringify(metadata))
  const nameRules = templateData.name.rules
  const descriptionRules = templateData.description.rules

  const [titleTouched, setTitleTouched] = useState<boolean>(false)
  const [descriptionTouched, setDescriptionTouched] = useState<boolean>(false)
  const { t } = useTranslation()

  return (
    <Stack>
      <Divider />
      <Stack style={{ flexDirection: 'row', gap: '15px', alignItems: 'center' }}>
        <Title order={3}>{t('components.studio.info.title', 'Set Info')}</Title>
        {/* <Text
          style={{
            color: theme.colors.textAndIcon[0],
            fontSize: '14px',
            fontFamily: 'monument-grotesk-regularitalic',
          }}
        >
          {t('components.studio.required')}
        </Text> */}
      </Stack>
      <Trans i18nKey="components.studio.info.Tagsdescription">
        <Text style={{ textAlign: 'left', color: theme.colors.paragraph[0] }}>
          This information will be shown most places where your item is viewed, such as in
          inventory, storefronts, and transactions.
        </Text>
      </Trans>
      <Stack style={{ textAlign: 'left', gap: '30px' }}>
        <Stack style={{ flexDirection: 'column', gap: '0' }}>
          <Text style={{ marginBottom: '10px', fontFamily: 'monument-grotesk-bold' }}>
            <Trans i18nKey="components.studio.info.name">What is this item called ? </Trans>
          </Text>
          <SpecialInputTile
            value={title}
            placeholder="Input your title here"
            rule={
              <StringSizeRule
                countChars={title.length}
                minChars={nameRules.min}
                maxChars={nameRules.max}
              />
            }
            errorMessage={
              (title.length > nameRules.max || title.length < nameRules.min) && titleTouched
                ? `Please type in a title that’s between ${nameRules.min} and ${nameRules.max} characters`
                : ''
            }
            onChange={e => {
              setTitleTouched(true)
              setTitle(e.target.value)
            }}
            tip="This title will appear everywhere your item does – across Spaces, inventories, storefronts, and transactions."
          />
        </Stack>
        <Stack style={{ flexDirection: 'column', gap: '0' }}>
          <Text style={{ margin: '10px 0', fontFamily: 'monument-grotesk-bold' }}>
            <Trans i18nKey="components.studio.info.description">
              Write a description of your item
            </Trans>
          </Text>
          <SpecialInputTile
            value={description}
            placeholder="Input your description here"
            rule={
              <StringSizeRule
                countChars={description.length}
                minChars={descriptionRules.min}
                maxChars={descriptionRules.max}
              />
            }
            errorMessage={
              (description.length > descriptionRules.max ||
                description.length < descriptionRules.min) &&
              descriptionTouched
                ? `Please type in a description that’s between ${descriptionRules.min} and ${descriptionRules.max} characters`
                : ''
            }
            onChange={e => {
              setDescriptionTouched(true)
              setDescription(e.target.value)
            }}
            tip="This information will be displayed in your Items tab, and any future Spaces, storefronts or applications that choose to display it."
            longInput
          />
        </Stack>
      </Stack>
      <Stack style={{ flexDirection: 'row', gap: '10px', alignItems: 'center', marginTop: '20px' }}>
        <RootButton secondary onClick={prevStep}>
          {t('buttons.previous', 'Previous')}
        </RootButton>
        <RootButton
          onClick={() => {
            saveDraft()
            nextStep()
          }}
          disabled={
            title === '' ||
            description === '' ||
            title.length < nameRules.min ||
            title.length > nameRules.max ||
            description.length < descriptionRules.min ||
            description.length > descriptionRules.max
          }
        >
          {t('buttons.continue')}
        </RootButton>
      </Stack>
    </Stack>
  )
}
export default Description
