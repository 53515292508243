import React, { useEffect } from 'react'

import SendModal from '@/components/Modals/Wallet/SendModal'
import { useBalancesContext } from '@/contexts/BalancesContext'
import { useModalContext } from '@/contexts/ModalContext'
import { useAuth } from '@/plugins/auth'

import AddToMetamaskModal from './Wallet/AddToMetamaskModal'
import ReceiveModal from './Wallet/ReceiveModal'
import SignatureModal from './Wallet/SignatureModal'
import UnwrapModal from './Wallet/UnwrapModal'

const ModalWrapper: React.FC = () => {
  const { modalType, closeModal } = useModalContext()
  const { status } = useAuth()
  const {
    ll1: { withdrawable: ll1Withdrawable },
    rl1: { withdrawable: rl1Withdrawable },
  } = useBalancesContext()

  useEffect(() => {
    if (status !== 'connected') {
      closeModal()
    }
  }, [status, closeModal])

  if (!modalType) {
    return null
  }

  switch (modalType) {
    case 'send':
      return <SendModal close={closeModal} />
    case 'receive':
      return <ReceiveModal close={closeModal} />
    case 'watchTokens':
      return <AddToMetamaskModal close={closeModal} />
    case 'signature':
      return <SignatureModal close={closeModal} />
    case 'unwrapRL1':
      return <UnwrapModal unwrap="RL1" amount={rl1Withdrawable} close={closeModal} />
    case 'unwrapLL1':
      return <UnwrapModal unwrap="LL1" amount={ll1Withdrawable} close={closeModal} />
    default:
      return null
  }
}

export default ModalWrapper
