import * as Sentry from '@sentry/react'
import { IconAlertCircle } from '@tabler/icons-react'
import { TFunction } from 'i18next'
import { closeSnackbar, enqueueSnackbar } from 'notistack'
import { ReactNode } from 'react'

import dark_banner from '@/assets/webp_converted/banner-default-dark.webp'
import light_banner from '@/assets/webp_converted/banner-default-light.webp'
import dark_pfp from '@/assets/webp_converted/pfp-faceless-default-dark.webp'
import light_pfp from '@/assets/webp_converted/pfp-faceless-default-light.webp'
import Snackbar from '@/components/Snackbar'
import { BLANK_ADDRESS } from '@/constants/blockchain'
import { InternalAuthStateConnectingUserIdentity } from '@/plugins/auth/AuthProvider/types/AuthInternalState'
import { noop } from '@/utils/helpers'
import { fetchIpfsMetadata, hashList, UserIdentity } from '@/utils/utils'

const version = hashList(['lamina1', process.env.REACT_APP_VERSION ?? '3.0.0'])

// Set default images for profile and banner
export function getDefaultProfileImage(isLightMode: boolean | undefined): string {
  return isLightMode ? light_pfp : dark_pfp
}

export function getDefaultBannerImage(isLightMode: boolean | undefined): string {
  return isLightMode ? light_banner : dark_banner
}

// Check current theme / color mode and set default images accordingly (default to dark mode)
// Note: These one-time functions are only used during startup. They do not dynamically track changes to the 'userTheme' variable in localStorage, hence not exported and used across the app.
const isLightMode = (): boolean => {
  if (typeof localStorage === 'undefined' || localStorage === null) {
    return false
  }

  const res = localStorage.getItem('userTheme')
  if (res) {
    try {
      return JSON.parse(res).isLightMode ?? false
    } catch {
      Sentry.withScope(scope => {
        scope.setTag('ignore_error', 'true')
      })
      return false
    }
  }
  return false
}
const defaultProfileImage = getDefaultProfileImage(isLightMode())
const defaultBannerImage = getDefaultBannerImage(isLightMode())

export function deleteUserIdentityIfDifferentVersion(userIdentity: UserIdentity): UserIdentity {
  const sameVersion = Object.entries(userIdentity).some(
    elem => elem[0] === 'version' && elem[1] === version
  )
  // If version is the same, update locally stored `userIdentity-${address}`
  if (!sameVersion) {
    localStorage.removeItem(`userIdentity-${userIdentity.address}`)
    return {
      ...userIdentity,
      profileImage: defaultProfileImage,
      version,
    }
  }
  return userIdentity
}

export function getSavedProfileImage(userIdentity: UserIdentity): string | undefined {
  if (!userIdentity) return undefined
  return userIdentity.profileImage ?? defaultProfileImage
}

export function getSavedBannerImage(userIdentity: UserIdentity): string | undefined {
  if (!userIdentity) return undefined
  return userIdentity.bannerImage ?? defaultBannerImage
}

export function getUserIdentityPromise(
  state: InternalAuthStateConnectingUserIdentity,
  getL1nsName: (address: string) => Promise<string>,
  getL1nsProfile: (account: string) => Promise<string>,
  t: TFunction
): Promise<UserIdentity> {
  const { address } = state
  const cachedUserIdentityStr = localStorage.getItem(`userIdentity-${address}`)
  if (!cachedUserIdentityStr) {
    localStorage.setItem(`userIdentity-${address}`, JSON.stringify({ version }))
  }
  const cachedUserIdentity: UserIdentity | undefined =
    (cachedUserIdentityStr &&
      (() => {
        try {
          return JSON.parse(cachedUserIdentityStr)
        } catch {
          return undefined
        }
      })()) ||
    undefined
  // Fallback to Web3Auth image or default avatar for external wallets.
  const profileImage =
    cachedUserIdentity?.profileImage && cachedUserIdentity.profileImage !== defaultProfileImage
      ? cachedUserIdentity.profileImage
      : defaultProfileImage

  // Set default username if no identity on chain
  const username = cachedUserIdentity?.username ?? ''

  const snackBars: ReactNode[] = []

  const uId: UserIdentity = {
    version: cachedUserIdentity?.version ?? '',
    address,
    username,
    nickname: '',
    profileImage,
    bannerImage: '',
    bio: '',
    link: '',
  }

  // Set default profile image if none on chain
  uId.profileImage = getSavedProfileImage(uId) ?? uId.profileImage

  // Set default banner image if none on chain
  uId.bannerImage = getSavedBannerImage(uId) ?? uId.bannerImage

  const uIdPromise = Promise.resolve(deleteUserIdentityIfDifferentVersion(uId))
    .then(async newUserIdentity => {
      // Check if user has a username
      try {
        const l1nsUsername = await getL1nsName(address)

        if (l1nsUsername && l1nsUsername !== BLANK_ADDRESS) {
          newUserIdentity.username = l1nsUsername
        } else {
          newUserIdentity.username = ''
        }
      } catch (e: any) {
        // if not connected to ID subnet chain or error when connecting
        console.error(e)
        snackBars.push(
          <Snackbar
            value={t(
              'errors.snackbar.idSubnet.username',
              `Error fetching Username for {{address}}. Using locally stored username if any.`,
              {
                address,
              }
            )}
            title={t('errors.snackbar.idSubnet.title', 'Not connected to ID Subnet')}
            icon={<IconAlertCircle size="14px" />}
          />
        )
      }
      return newUserIdentity
    })
    // Check if user has a profile
    .then(async newUserIdentity => {
      try {
        const profile = await getL1nsProfile(address)
        const profileMetadata = await fetchIpfsMetadata(profile)

        if (profileMetadata) {
          newUserIdentity.nickname = profileMetadata.name
          newUserIdentity.link = profileMetadata.creator.link
          newUserIdentity.bio = profileMetadata.description
          newUserIdentity.profileImage = profileMetadata.image
          newUserIdentity.bannerImage = profileMetadata.bannerImage
        } else {
          newUserIdentity.nickname = ''
          newUserIdentity.link = ''
          newUserIdentity.bio = ''
          newUserIdentity.profileImage = defaultProfileImage
          newUserIdentity.bannerImage = defaultBannerImage
        }
      } catch (e: any) {
        // if not connected to ID subnet chain or error when connecting
        snackBars.push(
          <Snackbar
            value={t(
              'errors.snackbar.idSubnet.profile',
              `Error fetching User Profile. Using locally stored profile if any.`
            )}
            title={t('errors.snackbar.idSubnet.title', 'Not connected to ID Subnet')}
            icon={<IconAlertCircle size="14px" />}
          />
        )
        console.error('USER IDENTITY ERROR:', e, newUserIdentity.username)
      }

      // set userIdentity to local storage
      localStorage.setItem(`userIdentity-${address}`, JSON.stringify(newUserIdentity))
      return newUserIdentity
    })
    .finally(() => {
      if (snackBars.length) {
        closeSnackbar()
        snackBars.forEach(enqueueSnackbar)
      }
    })

  uIdPromise.catch(noop)

  return uIdPromise
}
