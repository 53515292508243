import { useMemo } from 'react'

import { LL1_ADDRESS } from '@/constants/blockchain'
import LL1 from '@/contracts/LL1'
import { useCustomContractRead, useCustomContractReadBalance } from '@/hooks/useCustomContract'
import { l1NativeWagmiConfig } from '@/plugins/auth/config'
import { parseError } from '@/utils/helpers'
import { processBalance } from '@/utils/utils'

import { useChainValues } from './useChain'

const PERIOD_LENGTH = 7884000 // 1 quarter
const DAY_LENGTH = 86400 // 1 day
const NUM_DAYS = PERIOD_LENGTH / DAY_LENGTH

const useLL1 = () => {
  // Get block number
  const { blockNumber } = useChainValues(l1NativeWagmiConfig.id)

  // Supply
  const {
    value: supply,
    isLoading: isLoadingSupply,
    error: errorSupply,
  } = useCustomContractReadBalance(
    {
      chainId: l1NativeWagmiConfig.id,
      address: LL1_ADDRESS,
      args: [],
      abi: LL1,
      functionName: 'totalSupply',
    },
    blockNumber
  )

  // Deposited
  const {
    value: deposited,
    isLoading: isLoadingDeposited,
    error: errorDeposited,
  } = useCustomContractReadBalance(
    {
      chainId: l1NativeWagmiConfig.id,
      address: LL1_ADDRESS,
      args: [],
      abi: LL1,
      functionName: 'totalDeposited',
    },
    blockNumber
  )

  // Withdrawn
  const {
    value: withdrawn,
    isLoading: isLoadingWithdrawn,
    error: errorWithdrawn,
  } = useCustomContractReadBalance(
    {
      chainId: l1NativeWagmiConfig.id,
      address: LL1_ADDRESS,
      args: [],
      abi: LL1,
      functionName: 'withdrawnTokens',
    },
    blockNumber
  )

  // Unlockable_10k
  const {
    value: unlockable10k,
    isLoading: isLoadingUnlockable10k,
    error: errorUnlockable10k,
  } = useCustomContractRead(
    {
      chainId: l1NativeWagmiConfig.id,
      address: LL1_ADDRESS,
      args: [],
      abi: LL1,
      functionName: 'unlockable_10k',
    },
    blockNumber
  )

  // Unlock rate lenght
  const {
    value: unlockRateLen,
    isLoading: isLoadingUnlockRateLen,
    error: errorUnlockRateLen,
  } = useCustomContractRead(
    {
      chainId: l1NativeWagmiConfig.id,
      address: LL1_ADDRESS,
      args: [],
      abi: LL1,
      functionName: 'rateLength',
    },
    blockNumber
  )

  // Index to get
  const rateIndex = useMemo(() => (unlockRateLen ? unlockRateLen - 1 : 0), [unlockRateLen])

  // Unlock rate
  const {
    value: unlockRateValue,
    isLoading: isLoadingUnlockRate,
    error: errorUnlockRate,
  } = useCustomContractRead(
    {
      chainId: l1NativeWagmiConfig.id,
      address: LL1_ADDRESS,
      args: [BigInt(rateIndex)],
      abi: LL1,
      functionName: 'unlockRates',
    },
    blockNumber
  )

  // Compute rate per day
  const unlockRate = useMemo(
    () => (unlockRateValue ? unlockRateValue / 10000 / NUM_DAYS : 0),
    [unlockRateValue]
  )

  // Compute unlockable RL1
  const unlockable = useMemo(() => {
    if (deposited && withdrawn && unlockable10k) {
      // Compute how much has been unlocked
      const unlocked = parseFloat(deposited.value.toString()) * (unlockable10k / 1000000)
      // Subtract how much has been withdrawn
      const remaining = unlocked - parseFloat(withdrawn.value.toString())
      return processBalance(BigInt(remaining))
    }
    return processBalance(BigInt(0))
  }, [deposited, withdrawn, unlockable10k])

  // Loading
  const isLoading = useMemo(
    () =>
      isLoadingSupply ||
      isLoadingDeposited ||
      isLoadingWithdrawn ||
      isLoadingUnlockable10k ||
      isLoadingUnlockRateLen ||
      isLoadingUnlockRate,
    [
      isLoadingSupply,
      isLoadingDeposited,
      isLoadingWithdrawn,
      isLoadingUnlockable10k,
      isLoadingUnlockRateLen,
      isLoadingUnlockRate,
    ]
  )

  // Error
  const errors = [
    errorSupply,
    errorDeposited,
    errorWithdrawn,
    errorUnlockable10k,
    errorUnlockRateLen,
    errorUnlockRate,
  ]
  const error = useMemo(() => parseError(errors), errors)

  return {
    supply,
    unlockable,
    unlockRate,
    isLoading,
    error: {
      call: `ll1.${error.call}`,
      msg: error.msg,
      isError: error.isError,
    },
  }
}

export default useLL1
