import { useCallback, useEffect, useMemo, useState } from 'react'

import {
  AIRDROP_CONTRACT_ADDRESS,
  AIRDROP_MSIG_ADDRESS,
  CMC_BOT,
  FOUNDATION_MSIG_ADDRESS,
  STAKE_ADDRESS,
} from '@/constants/blockchain'
import Stake from '@/contracts/Stake'
import { useCustomContractRead, useCustomContractReadBalance } from '@/hooks/useCustomContract'
import useLL1 from '@/hooks/useLL1'
import useRewards from '@/hooks/useRewards'
import useRL1 from '@/hooks/useRL1'
import { l1NativeWagmiConfig } from '@/plugins/auth/config'
import { CustomError, parseError } from '@/utils/helpers'
import { emptyBalance, processBalance, ProcessedBalance } from '@/utils/utils'

import { useChainValues } from './useChain'
import useCustomBalance from './useCustomBalance'

const TOTAL_SUPPLY = BigInt(15e26)

export type EconomicsHook = {
  started: boolean
  startDay: number
  getCurrentDay: () => number
  getDayStart: (day: number) => string
  totalStaked: ProcessedBalance
  totalStakeSupply: ProcessedBalance
  stakeMaxDays: number
  stakeMultiplier: number
  ll1Supply: ProcessedBalance
  ll1Unlockable: ProcessedBalance
  ll1UnlockRate: number
  rl1Supply: ProcessedBalance
  rl1SupplyLeft: ProcessedBalance
  rl1Unlockable: ProcessedBalance
  rl1UnlockRate: number
  rl1CurrentPeriod: number
  rl1CurrentPeriodStart: number
  rl1CurrentPeriodEnd: number
  rl1Minted: ProcessedBalance
  rl1PerDay: number
  locked: ProcessedBalance
  circulating: ProcessedBalance
  foundation: ProcessedBalance
  airdrop: ProcessedBalance
  distributed: ProcessedBalance
  othersBalance: ProcessedBalance
  rewardsPercentages: number[]
  isLoading: boolean
  error: CustomError
}

export const EmptyEconomics: EconomicsHook = {
  started: false,
  startDay: 0,
  getCurrentDay: () => 0,
  getDayStart: () => '',
  totalStaked: emptyBalance,
  totalStakeSupply: emptyBalance,
  stakeMaxDays: 90,
  stakeMultiplier: 1,
  ll1Supply: emptyBalance,
  ll1Unlockable: emptyBalance,
  ll1UnlockRate: 0,
  rl1Supply: emptyBalance,
  rl1SupplyLeft: emptyBalance,
  rl1Unlockable: emptyBalance,
  rl1UnlockRate: 0,
  rl1CurrentPeriod: 0,
  rl1CurrentPeriodStart: 0,
  rl1CurrentPeriodEnd: 0,
  rl1Minted: emptyBalance,
  rl1PerDay: 0,
  locked: emptyBalance,
  circulating: emptyBalance,
  foundation: emptyBalance,
  airdrop: emptyBalance,
  distributed: emptyBalance,
  othersBalance: emptyBalance,
  rewardsPercentages: [],
  isLoading: false,
  error: {
    call: '',
    msg: undefined,
    isError: false,
  },
}

export const useEconomics = (): EconomicsHook => {
  // Get block number
  const { blockNumber } = useChainValues(l1NativeWagmiConfig.id)
  const [othersBalance, setOthersBalance] = useState(emptyBalance)

  // Total L1 staked
  const {
    value: totalStaked,
    isLoading: isLoadingTotalStaked,
    error: errorTotalStaked,
  } = useCustomBalance(l1NativeWagmiConfig.id, STAKE_ADDRESS, blockNumber, true)

  // Total Stake supply
  const {
    value: totalStakeSupply,
    isLoading: isLoadingTotalStakeSupply,
    error: errorTotalStakeSupply,
  } = useCustomContractReadBalance(
    {
      chainId: l1NativeWagmiConfig.id,
      address: STAKE_ADDRESS,
      args: [],
      abi: Stake,
      functionName: 'totalSupply',
    },
    blockNumber
  )

  // Check if staking has started
  const {
    value: started,
    isLoading: isLoadingHasStarted,
    error: errorHasStarted,
  } = useCustomContractRead(
    {
      chainId: l1NativeWagmiConfig.id,
      address: STAKE_ADDRESS,
      args: [],
      abi: Stake,
      functionName: 'hasStarted',
    },
    blockNumber
  )

  // Get start day (unix style timestamp)
  const {
    value: startDay,
    isLoading: isLoadingStartDay,
    error: errorStartDay,
  } = useCustomContractRead(
    {
      chainId: l1NativeWagmiConfig.id,
      address: STAKE_ADDRESS,
      args: [],
      abi: Stake,
      functionName: 'startDay',
    },
    blockNumber
  )

  // Get seconds per day
  const {
    value: secondsPerDay,
    isLoading: isLoadingSecondsPerDay,
    error: errorSecondsPerDay,
  } = useCustomContractRead(
    {
      chainId: l1NativeWagmiConfig.id,
      address: STAKE_ADDRESS,
      args: [],
      abi: Stake,
      functionName: 'secondsPerDay',
    },
    blockNumber
  )

  // Stake max days
  const {
    value: stakeMaxDays,
    isLoading: isLoadingStakeMaxDays,
    error: errorStakeMaxDays,
  } = useCustomContractRead(
    {
      chainId: l1NativeWagmiConfig.id,
      address: STAKE_ADDRESS,
      args: [],
      abi: Stake,
      functionName: 'maxDays',
    },
    blockNumber
  )

  // Stake multiplier
  const {
    value: stakeMultiplierValue,
    isLoading: isLoadingStakeMultiplier,
    error: errorStakeMultiplier,
  } = useCustomContractRead(
    {
      chainId: l1NativeWagmiConfig.id,
      address: STAKE_ADDRESS,
      args: [],
      abi: Stake,
      functionName: 'multiplier',
    },
    blockNumber
  )

  const stakeMultiplier = useMemo(() => {
    if (stakeMultiplierValue) {
      return parseFloat(stakeMultiplierValue.toString()) / 1000
    }
    return 0
  }, [stakeMultiplierValue])

  // LL1
  const {
    supply: ll1Supply,
    unlockable: ll1Unlockable,
    unlockRate: ll1UnlockRate,
    isLoading: isLoadingLL1,
    error: errorLL1,
  } = useLL1()

  // RL1
  const {
    supply: rl1Supply,
    supplyLeft: rl1SupplyLeft,
    unlockable: rl1Unlockable,
    unlockRate: rl1UnlockRate,
    currentPeriod: rl1CurrentPeriod,
    currentPeriodStart: rl1CurrentPeriodStart,
    currentPeriodEnd: rl1CurrentPeriodEnd,
    minted: rl1Minted,
    mintedPerDay: rl1MintedPerDay,
    isLoading: isLoadingRL1,
    error: errorRL1,
  } = useRL1()

  // Compute locked supply
  const locked = useMemo(() => {
    if (totalStaked && ll1Supply && rl1Supply) {
      return processBalance(totalStaked.value + ll1Supply.value + rl1Supply.value)
    }
    return processBalance(BigInt(0))
  }, [totalStaked, ll1Supply, rl1Supply])

  // Compute circulating supply
  const circulating = useMemo(() => processBalance(TOTAL_SUPPLY - locked.value), [locked])

  // Check others balance
  const getOthersBalance = useCallback(async () => {
    try {
      const data = await fetch(`${CMC_BOT}/others`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
      const balance = await data.json()
      const balanceWei = BigInt(balance) * BigInt(1e18)
      setOthersBalance(processBalance(balanceWei))
    } catch (e) {
      setOthersBalance(emptyBalance)
    }
  }, [])

  useEffect(() => {
    getOthersBalance()
  }, [getOthersBalance])

  // Check foundation multisig balance
  const {
    value: foundation,
    isLoading: isLoadingFoundation,
    error: errorFoundation,
  } = useCustomBalance(l1NativeWagmiConfig.id, FOUNDATION_MSIG_ADDRESS, blockNumber, true)

  // Check airdrop contract + multisig balances
  const {
    value: airdropContract,
    isLoading: isLoadingAirdropContract,
    error: errorAirdropContract,
  } = useCustomBalance(l1NativeWagmiConfig.id, AIRDROP_CONTRACT_ADDRESS, blockNumber, true)
  const {
    value: airdropMsig,
    isLoading: isLoadingAirdropMsig,
    error: errorAirdropMsig,
  } = useCustomBalance(l1NativeWagmiConfig.id, AIRDROP_MSIG_ADDRESS, blockNumber, true)

  // Compute total airdrop balance
  const airdrop = useMemo(() => {
    if (airdropContract && airdropMsig) {
      return processBalance(airdropContract.value + airdropMsig.value)
    }
    return emptyBalance
  }, [airdropContract, airdropMsig])

  // Compute distributed balance
  const distributed = useMemo(() => {
    if (foundation && airdrop) {
      return processBalance(
        circulating.value - foundation.value - airdrop.value - othersBalance.value
      )
    }
    return emptyBalance
  }, [circulating, foundation, airdrop, othersBalance])

  // Rewards percentage going to STAKE contract
  const { rewardsPercentages, isLoading: isLoadingRewards, error: errorRewards } = useRewards()

  // Compute RL1 distributed to STAKE holders per day
  const rl1PerDay = useMemo(() => {
    if (rl1MintedPerDay && rewardsPercentages) {
      return parseFloat(rl1MintedPerDay.value.toString()) * rewardsPercentages[0]
    }
    return 0
  }, [rl1MintedPerDay, totalStakeSupply, rewardsPercentages])

  // Function to get current day
  const getCurrentDay = useCallback(() => {
    if (!startDay || !secondsPerDay) {
      return 0
    }
    return Math.floor((Math.floor(Date.now() / 1000) - Number(startDay)) / Number(secondsPerDay))
  }, [startDay, secondsPerDay])

  // Function to get the start time of a day
  const getDayStart = useCallback(
    (day: number) => {
      if (!startDay || !secondsPerDay) {
        return ''
      }
      const unixTime = Number(startDay) + day * Number(secondsPerDay)
      return new Date(unixTime * 1000).toUTCString()
    },
    [startDay, secondsPerDay]
  )

  // Loading
  const isLoading = useMemo(
    () =>
      isLoadingTotalStaked ||
      isLoadingTotalStakeSupply ||
      isLoadingHasStarted ||
      isLoadingStartDay ||
      isLoadingSecondsPerDay ||
      isLoadingStakeMaxDays ||
      isLoadingStakeMultiplier ||
      isLoadingLL1 ||
      isLoadingRL1 ||
      isLoadingFoundation ||
      isLoadingAirdropContract ||
      isLoadingAirdropMsig ||
      isLoadingRewards,
    [
      isLoadingTotalStaked,
      isLoadingTotalStakeSupply,
      isLoadingHasStarted,
      isLoadingStartDay,
      isLoadingSecondsPerDay,
      isLoadingStakeMaxDays,
      isLoadingStakeMultiplier,
      isLoadingLL1,
      isLoadingRL1,
      isLoadingFoundation,
      isLoadingAirdropContract,
      isLoadingAirdropMsig,
      isLoadingRewards,
    ]
  )

  // Error
  const errors = [
    errorTotalStaked,
    errorTotalStakeSupply,
    errorHasStarted,
    errorStartDay,
    errorSecondsPerDay,
    errorStakeMaxDays,
    errorStakeMultiplier,
    errorLL1,
    errorRL1,
    errorFoundation,
    errorAirdropContract,
    errorAirdropMsig,
    errorRewards,
  ]
  const error = useMemo(() => parseError(errors), errors)

  return {
    started: started || false,
    startDay: Number(startDay) || 0,
    getCurrentDay,
    getDayStart,
    totalStaked,
    totalStakeSupply,
    stakeMaxDays: stakeMaxDays || 90,
    stakeMultiplier,
    ll1Supply,
    ll1Unlockable,
    ll1UnlockRate,
    rl1Supply,
    rl1SupplyLeft,
    rl1Unlockable,
    rl1UnlockRate,
    locked,
    circulating,
    foundation,
    airdrop,
    distributed,
    othersBalance,
    rl1CurrentPeriod: rl1CurrentPeriod === undefined ? 0 : rl1CurrentPeriod,
    rl1CurrentPeriodStart,
    rl1CurrentPeriodEnd,
    rl1Minted,
    rl1PerDay,
    rewardsPercentages,
    isLoading,
    error: {
      call: `economics.${error.call}`,
      msg: error.msg,
      isError: error.isError,
    },
  }
}
