import { useSubscription } from '@apollo/client'
import {
  ActionIcon,
  Container,
  Divider,
  Grid,
  Menu,
  Stack,
  Text,
  Title,
  Tooltip,
  useMantineTheme,
} from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'

import Web3Button from '@/components/Buttons/Web3Button'
import { useAuth } from '@/plugins/auth'
import { getDefaultProfileImage } from '@/plugins/auth/AuthProvider/effects/functions'
import { SUB_TOTAL_OWNED_ITEMS, SubscriptionTotalOwnedItems } from '@/schemas/tokens.schema'

import useLocalStorage from '../../hooks/useLocalStorage'
import { StoredTheme, truncateIdentity, UserIdentity } from '../../utils/utils'
import OpenMenuArrow from '../Animations/OpenMenuArrow/OpenMenuArrow'
import { displayImage } from '../Profile/ProfileHeader'
import classes from './UserHeader.module.css'
import WalletButton from './WalletButton'

const MAX_CHARS_NICK = 12

const UserHeader: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { address } = useAuth()
  const [menuOpened, setMenuOpened] = useState<boolean>(false)
  const theme = useMantineTheme()
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`)
  const [userIdentity] = useLocalStorage<UserIdentity>(`userIdentity-${address}`)
  const profilePath = useMemo(
    () => `/profile/${String(userIdentity?.username || address)}`,
    [address]
  )
  // default profile image
  const [userTheme] = useLocalStorage<StoredTheme>('userTheme')
  const defaultPfp = useMemo(() => getDefaultProfileImage(userTheme?.isLightMode), [userTheme])

  // Subscribe to total number of items user owns
  const { data } = useSubscription<SubscriptionTotalOwnedItems>(SUB_TOTAL_OWNED_ITEMS, {
    variables: {
      account: address?.toLowerCase(),
    },
  })
  const nItems = useMemo(() => data?.total.agg.count || 0, [data])

  const displayAddress = useMemo(() => {
    if (!address) {
      return ''
    }
    if (userIdentity?.username) {
      return userIdentity.username
    }
    return address
  }, [userIdentity?.username, address])

  const openProfileMenu = useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation()
    setMenuOpened(previous => !previous)
  }, [])

  const navigateToProfile = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.stopPropagation()
      navigate(profilePath)
    },
    [navigate, profilePath]
  )

  return (
    <>
      {address && displayAddress && userIdentity ? (
        <Container className={classes.container}>
          <WalletButton address={address} username={userIdentity?.username} />
          <Divider
            style={{
              height: '100%',
              backgroundColor: theme.colors.splitter[0],
              width: '1px',
            }}
          />
          <Container
            className={classes.userContainer}
            style={{
              border: menuOpened
                ? `1px solid ${theme.colors.splitter[0]}`
                : '1px solid transparent',
              backgroundColor: menuOpened ? theme.colors.background2[0] : 'transparent',
              height: '80px',
            }}
          >
            <Menu
              trigger="hover"
              openDelay={500}
              closeDelay={100000}
              opened={menuOpened}
              onClose={() => setMenuOpened(false)}
              closeOnClickOutside
              closeOnEscape
              offset={-5}
            >
              <Menu.Target>
                <Grid
                  onClick={isMobile ? openProfileMenu : navigateToProfile}
                  style={{
                    cursor: 'pointer',
                  }}
                  gutter={isMobile ? 0 : '14px'}
                >
                  <Grid.Col span="content" style={{ height: 'auto' }}>
                    <img
                      className={classes.profileImage}
                      src={displayImage(defaultPfp, userIdentity?.profileImage)}
                      alt="profile"
                    />
                  </Grid.Col>
                  {!isMobile && (
                    <Grid.Col span="auto" className={classes.userInfoColumn}>
                      <Stack
                        style={{
                          flexDirection: 'column',
                          justifyContent: 'flex-start',
                          gap: '5px',
                        }}
                      >
                        <Tooltip
                          withArrow
                          position="top"
                          label={userIdentity.nickname}
                          style={{
                            display:
                              userIdentity.nickname.length > MAX_CHARS_NICK ? 'inline' : 'none',
                          }}
                        >
                          <Title
                            order={4}
                            className={classes.nickname}
                            style={{ fontSize: '16px', overflow: 'hidden' }}
                          >
                            {userIdentity.nickname === ''
                              ? t('components.userHeader.noNickname', 'No Name')
                              : truncateIdentity(userIdentity.nickname, MAX_CHARS_NICK)}
                          </Title>
                        </Tooltip>
                        <Text>
                          {
                            (nItems === 1
                              ? t('components.userHeader.item', '1 Item')
                              : t('components.userHeader.items', '{{nItems}} Items', {
                                  nItems,
                                })) as string
                          }
                        </Text>
                      </Stack>
                    </Grid.Col>
                  )}
                  <Grid.Col
                    span="content"
                    className={classes.dropdownButton}
                    onClick={openProfileMenu}
                  >
                    <ActionIcon style={{ marginLeft: isMobile ? '10px' : '0px' }}>
                      <OpenMenuArrow menuOpened={menuOpened} />
                    </ActionIcon>
                  </Grid.Col>
                </Grid>
              </Menu.Target>
              <Menu.Dropdown className={classes.dropdown}>
                <Divider className={classes.menuItemsDivider} />
                <Stack className={classes.menuItems}>
                  <Link to={profilePath}>{t('components.userHeader.profile', 'Profile')}</Link>
                  <Link to="/usersettings">{t('components.userHeader.settings', 'Settings')}</Link>
                  <Link to="/helpcenter">
                    {t('components.userHeader.helpCenter', 'Help Center')}
                  </Link>
                  <Web3Button signOutLink />
                </Stack>
              </Menu.Dropdown>
            </Menu>
          </Container>
        </Container>
      ) : (
        <Web3Button />
      )}
    </>
  )
}

export default UserHeader
