import { ActionIcon, Modal, Stack, Switch, Title } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { IconEye, IconEyeOff } from '@tabler/icons-react'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { erc20Abi, isAddress, parseUnits, TransactionReceipt } from 'viem'

import {
  AVAX_CHAIN_TICKER,
  BLANK_ADDRESS,
  BRIDGE_AVAX_ERC20_REMOTE_ADDRESS,
  BRIDGE_L1_ERC20_REMOTE_ADDRESS,
  NATIVE_CHAIN_TICKER,
} from '@/constants/blockchain'
import { useChainFeaturesContext } from '@/contexts/ChainFeaturesContext'
import useBridge from '@/hooks/useBridge'
import { useChainFunctions } from '@/hooks/useChain'
import useEstimatedFee from '@/hooks/useEstimatedFee'
import useL1NS from '@/hooks/useL1NS'
import useThrowAsync from '@/hooks/useThrowAsync'
import { useAuth } from '@/plugins/auth'
import {
  avalancheConfig,
  avalancheWagmiConfig,
  l1NativeConfig,
  l1NativeWagmiConfig,
} from '@/plugins/auth/config'
import { switchChain } from '@/plugins/auth/utils'
import { NetworkOptions, networkOptions } from '@/utils/utils'

import RootButton from '../Buttons/RootButton'
import SendDropdownButton from '../Buttons/SendDropdownButton'
import CurrencyInput from '../CurrencyInput'
import ErrorMessage from '../ErrorMessage'
import { checkInputHandleAddress } from '../Identity/rules'
import Loading from '../Loading'
import ModalSkeleton from '../Modals/ModalSkeleton'
import ConfirmTransaction from '../Modals/Send/ConfirmTransaction'
import { AtIcon, CheckedIcon, InvalidIcon, SpecialInputText } from '../SpecialInputText'
import OnTransfer from './OnTransfer'
import PayBridgeFee from './PayBridgeFee'
import classes from './Send.module.css'
import TransactionSummary from './TransactionSummary'

// link chainId to network options
const chainIdNetwork = (chainId: string) =>
  chainId === l1NativeConfig.chainIdHex ? networkOptions.l1 : networkOptions.avax

interface SendProps {
  close: () => void
  setOkToClose: (okToClose: boolean) => void
}

const isBridgeable = (
  origin: NetworkOptions,
  token: string,
  hasBridgeL1: boolean,
  hasBridgeAvax: boolean
) => {
  if (origin === networkOptions.l1) {
    return token === NATIVE_CHAIN_TICKER ? hasBridgeL1 : hasBridgeAvax
  }
  return (
    origin === networkOptions.avax && (token === AVAX_CHAIN_TICKER ? hasBridgeAvax : hasBridgeL1)
  )
}

const Send: React.FC<SendProps> = ({ close, setOkToClose }) => {
  const { t } = useTranslation()
  // Set up network dropdown options and translations. Since i18next.t() do not allow for keys to be non String Literal Types, we have to manually initialize this array and rewrite the keys as string literals.
  const networkDropdownOptions: NetworkOptions[] = [networkOptions.l1, networkOptions.avax]

  const { address } = useAuth()
  const { hasFeature } = useChainFeaturesContext()
  const hasBridgeL1 = hasFeature('bridge-l1')
  const hasBridgeAvax = hasFeature('bridge-avax')

  // Logged In Info
  const isNotMetamask = localStorage.getItem('Web3Auth-cachedAdapter') !== 'metamask'

  // Network Switch
  const [networkOrigin, setNetworkOrigin] = useState<NetworkOptions>(networkOptions.l1)
  const [networkDestination, setNetworkDestination] = useState<NetworkOptions>(networkOptions.l1)
  const [oldNetwork, setOldNetwork] = useState<NetworkOptions>(networkOptions.l1)
  const [loadingNetworkSwitch, setLoadingNetworkSwitch] = useState<boolean>(false)
  const [token, setToken] = useState<string>(NATIVE_CHAIN_TICKER)

  // Cross Chain Transaction Summary
  const crossChain = useMemo(
    () => ({
      token,
      networkOrigin,
      networkDestination,
    }),
    [token, networkOrigin, networkDestination]
  )

  // Can this token be bridged?
  const tokenBridgeable = useMemo(
    () => isBridgeable(networkOrigin, token, hasBridgeL1, hasBridgeAvax),
    [token, hasBridgeL1, hasBridgeAvax, networkOrigin]
  )

  // send to myself switch
  const [sendToMyself, setSendToMyself] = useState<boolean>(false)

  const { sendTransfer, writeContract } = useChainFunctions(l1NativeWagmiConfig)
  const { sendTransfer: sendTransferAvax, writeContract: writeContractAvax } =
    useChainFunctions(avalancheWagmiConfig)

  // Bridge
  const { nativeL1ToAvax, erc20AvaxToAvax, erc20L1ToLamina1, nativeAvaxToLamina1 } = useBridge()

  // Modal
  const [openedConfirmTx, { open: openConfirmTx, close: closeConfirmTx }] = useDisclosure(false)

  const [destinationAddress, setDestinationAddress] = useState({
    value: '',
    touched: false,
    error: '',
  })
  // const [recipientAddress, setRecipientAddress] = useState<string | null>(null)
  const recipientAddressRef = useRef<string | null>(null)
  const [amount, setAmount] = useState({ value: '', touched: false, error: '' })
  const [txHash, setTxHash] = useState<string | null>(null)
  const [sendingTx, setSendingTx] = useState<boolean | null>(null)

  // fees
  const { estimatedFee } = useEstimatedFee()

  // handle vs address
  const { getL1nsName, getL1nsAddress } = useL1NS()
  const [validSendAddress, setValidSendAddress] = useState<boolean>(false)
  const [isHandle, setIsHandle] = useState<boolean>(false)
  const [handleCheckStatus, setHandleCheckStatus] = useState({
    input: '',
    message: '',
    verified: false,
  })

  // send transaction error
  const [error, setError] = useState<string>('')
  const [openedError, { open: openError, close: closeError }] = useDisclosure(false)

  /* -------------------------------------------------------------------------- */
  /*     Memoized Variables Dependent on User Input (network, token, balance)   */
  /* -------------------------------------------------------------------------- */
  const withToggleToMyself = useMemo(
    () => networkOrigin !== networkDestination,
    [networkOrigin, networkDestination]
  )
  const noUsernameAllowed = useMemo(
    () => networkOrigin === networkOptions.avax || networkDestination === networkOptions.avax,
    [networkOrigin, networkDestination]
  )

  const readyToSend = useMemo(
    () =>
      !(
        sendingTx ||
        !destinationAddress.touched ||
        !amount.touched ||
        destinationAddress.error !== '' ||
        amount.error !== '' ||
        (noUsernameAllowed && !isAddress(destinationAddress.value))
      ),
    [sendingTx, destinationAddress, amount, noUsernameAllowed]
  )

  /* -------------------------------------------------------------------------- */
  /*                             Relayer/Bridge Fee                             */
  /* -------------------------------------------------------------------------- */
  // Pay Relayer AVAX Fee
  const payRelayer = useMemo(
    () => networkOrigin === networkOptions.l1 && networkDestination === networkOptions.avax,
    [networkOrigin, networkDestination]
  )
  // Relayer Fee Paid Status
  const relayerFeePaid = useRef<boolean | undefined>(undefined)
  const relayerFeeLoading = useRef<boolean>(false)
  // Lock Transaction Params on Relayer Fee Payment
  const txParamsLocked = useMemo(() => relayerFeePaid.current, [relayerFeePaid.current])
  const txSent = useRef<boolean>(false)

  // Update okToClose state
  const updateOkToClose = useCallback(() => {
    // if relayer fee does not have to be paid, allow user to close modal
    if (relayerFeePaid.current === undefined) {
      setOkToClose(true)
      return
    }
    // if relayer fee has been paid, allow user to close modal only if transaction is sent
    if (relayerFeePaid.current) {
      setOkToClose(txSent.current)
      return
    }
    // if none of the above (relayer fee not paid && tx not sent), do not allow user to close modal
    setOkToClose(false)
  }, [relayerFeePaid, txSent])

  /* -------------------------------------------------------------------------- */
  /*                        On Send: Confirm Transaction                        */
  /* -------------------------------------------------------------------------- */

  // Send Transaction Function: handle loading state, error handling, and transaction receipt
  const sendTransaction = useCallback(async () => {
    if (!recipientAddressRef.current) {
      setTxHash(null)
      return
    }
    setSendingTx(true)
    try {
      let receipt: string | TransactionReceipt | null = null
      // cross-chain transaction
      if (networkOrigin !== networkDestination) {
        if (networkOrigin === networkOptions.l1) {
          // if source is Lamina1 (L1) and destination is Avalanche (L1 ERC20)
          if (token === NATIVE_CHAIN_TICKER) {
            receipt = await nativeL1ToAvax(recipientAddressRef.current, amount.value)
          }
          // if source is Lamina1 (AVAX ERC20) and destination is Avalanche (AVAX)
          if (token === AVAX_CHAIN_TICKER) {
            receipt = await erc20AvaxToAvax(recipientAddressRef.current, amount.value)
          }
        } else {
          // if source is Avalanche (L1 ERC20) and destination is Lamina1 (L1)
          if (token === NATIVE_CHAIN_TICKER) {
            receipt = await erc20L1ToLamina1(recipientAddressRef.current, amount.value)
          }
          // if source is Avalanche (AVAX) and destination is Lamina1 (AVAX ERC20)
          if (token === AVAX_CHAIN_TICKER) {
            receipt = await nativeAvaxToLamina1(recipientAddressRef.current, amount.value)
          }
        }
      } else if (networkOrigin === networkOptions.l1) {
        // If sending L1 on L1, native, otherwise AVAX ERC20
        if (token === NATIVE_CHAIN_TICKER) {
          receipt = await sendTransfer(recipientAddressRef.current, amount.value)
        } else {
          receipt = await writeContract({
            address: BRIDGE_AVAX_ERC20_REMOTE_ADDRESS,
            abi: erc20Abi,
            functionName: 'transfer',
            args: [recipientAddressRef.current as `0x${string}`, parseUnits(amount.value, 18)],
          })
        }
      } else if (token === AVAX_CHAIN_TICKER) {
        // If sending AVAX on AVAX, native, otherwise L1 ERC20
        receipt = await sendTransferAvax(recipientAddressRef.current, amount.value)
      } else {
        receipt = await writeContractAvax({
          address: BRIDGE_L1_ERC20_REMOTE_ADDRESS,
          abi: erc20Abi,
          functionName: 'transfer',
          args: [recipientAddressRef.current as `0x${string}`, parseUnits(amount.value, 18)],
        })
      }
      if (receipt && typeof receipt !== 'string') {
        setTxHash((receipt as TransactionReceipt).transactionHash)
      } else if (receipt) {
        setTxHash(receipt)
      }
    } catch (err: any) {
      setSendingTx(false)
      if (!err.message.includes('User rejected the request.')) {
        setError(err.message)
        openError()
      }
    }
  }, [
    amount.value,
    networkOrigin,
    networkDestination,
    token,
    nativeL1ToAvax,
    erc20AvaxToAvax,
    erc20L1ToLamina1,
    nativeAvaxToLamina1,
    sendTransfer,
    sendTransferAvax,
    writeContract,
    writeContractAvax,
  ])

  // Handle Username/Address Validation:
  // check if username exists, set recipient address, and validate send address
  const handleCheckStatusAction = useCallback(async () => {
    // if network destination is Avalanche, only allow sending to address
    if (isHandle) {
      const reverseAddress = await getL1nsAddress(destinationAddress.value)
      if (reverseAddress && reverseAddress !== BLANK_ADDRESS) {
        setHandleCheckStatus({
          input: destinationAddress.value,
          message: `${reverseAddress}`,
          verified: true,
        })
        recipientAddressRef.current = reverseAddress
        setValidSendAddress(true)
      } else {
        setHandleCheckStatus({
          input: destinationAddress.value,
          message: '',
          verified: true,
        })
        setValidSendAddress(false)
        setDestinationAddress({
          value: destinationAddress.value,
          touched: true,
          error: 'Username not found',
        })
      }
    } else {
      const reverseName = await getL1nsName(destinationAddress.value)
      if (reverseName) {
        setHandleCheckStatus({
          input: destinationAddress.value,
          message: `${reverseName}`,
          verified: true,
        })
      } else {
        setHandleCheckStatus({
          input: destinationAddress.value,
          message: '',
          verified: true,
        })
      }
      recipientAddressRef.current = destinationAddress.value
      setValidSendAddress(true)
    }
  }, [destinationAddress.value, getL1nsAddress, getL1nsName, isHandle, sendTransaction])

  // Handle transaction submission:
  // validate username, network switch, send transaction (dependent on token), error handling
  const submitTransaction = useCallback(async () => {
    try {
      await handleCheckStatusAction()
      // TODO: change function call to depend on network and token
      await sendTransaction()
      txSent.current = true
      updateOkToClose()
    } catch (err: any) {
      console.error('An error occurred:', err)
      if (!err.message.includes('User rejected the request.')) {
        setError(err.message)
        openError()
      }
    }
  }, [handleCheckStatusAction, sendTransaction, setError, openError])

  // Push transaction on send button click
  const pushTransaction = useCallback(async () => {
    // If user is logged in through Web3Auth, open the confirm transaction modal
    if (isNotMetamask) {
      openConfirmTx()
    } else {
      await submitTransaction()
    }
  }, [openConfirmTx, submitTransaction])

  // Modal confirmation for Web3Auth
  const confirm = useCallback(async () => {
    closeConfirmTx()
    await submitTransaction()
  }, [closeConfirmTx, submitTransaction])

  const throwAsync = useThrowAsync()

  /* -------------------------------------------------------------------------- */
  /*                             Handle Input Fields                            */
  /* -------------------------------------------------------------------------- */
  const handleDestinationChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
      const { value } = e.target
      setIsHandle(!isAddress(value))
      const destinationError = checkInputHandleAddress(t, value, !isAddress(value))
      setDestinationAddress({
        value: value.toLowerCase(),
        touched: true,
        error: destinationError,
      })
      setValidSendAddress(false)
      setHandleCheckStatus({
        input: value.toLowerCase(),
        message: '',
        verified: false,
      })
      setSendingTx(null)
    },
    [t]
  )

  /* -------------------------------------------------------------------------- */
  /*                              Input Field Icons                             */
  /* -------------------------------------------------------------------------- */

  // Switch handle and address on click
  const switchAddressHandle = useCallback(() => {
    setIsHandle(!isHandle)
    if (handleCheckStatus.message !== '') {
      setDestinationAddress({
        value:
          destinationAddress.value === handleCheckStatus.input
            ? handleCheckStatus.message
            : handleCheckStatus.input,
        touched: true,
        error: '',
      })
    }
  }, [isHandle, handleCheckStatus.message, handleCheckStatus.input, destinationAddress.value])

  // Left Icon showing validation status
  const iconLeft = useMemo(
    () =>
      handleCheckStatus.verified ? (
        validSendAddress ? (
          <CheckedIcon />
        ) : (
          <InvalidIcon />
        )
      ) : (
        <AtIcon />
      ),
    [validSendAddress, handleCheckStatus]
  )

  // Right Icon to switch between handle and address
  const iconRight = useMemo(
    () =>
      validSendAddress && handleCheckStatus.message ? (
        <ActionIcon onClick={() => switchAddressHandle()} className={classes.actionButton}>
          {isHandle ? <IconEye size={18} /> : <IconEyeOff size={18} />}
        </ActionIcon>
      ) : null,
    [
      validSendAddress,
      handleCheckStatus.message,
      classes.actionButton,
      isHandle,
      switchAddressHandle,
    ]
  )

  /* -------------------------------------------------------------------------- */
  /*                               Network Change                               */
  /* -------------------------------------------------------------------------- */
  // Handle dropdown menu change for origin network
  const handleNetworkOriginChange = useCallback(
    (selectedOption: NetworkOptions) => {
      setOldNetwork(networkOrigin)
      setNetworkOrigin(selectedOption)
      // Default to same network send, simplifies supported bridges logic
      setNetworkDestination(selectedOption)
    },
    [networkOrigin, setNetworkOrigin, setOldNetwork]
  )

  // Handle dropdown menu change for destination network
  const handleNetworkDestinationChange = useCallback(
    (selectedOption: NetworkOptions) => {
      setNetworkDestination(selectedOption)
      setSendToMyself(false)
    },
    [setNetworkDestination, setSendToMyself]
  )

  // When token is changed, set network destination to same as origin if bridging not supported
  const handleTokenChange = useCallback(
    (selectedToken: string) => {
      setToken(selectedToken)
      if (!isBridgeable(networkOrigin, selectedToken, hasBridgeL1, hasBridgeAvax)) {
        setNetworkDestination(networkOrigin)
      }
    },
    [hasBridgeL1, hasBridgeAvax, networkOrigin, setToken]
  )

  // Network Change
  const networkChange = useCallback(async () => {
    setLoadingNetworkSwitch(true)
    try {
      switch (networkOrigin) {
        case networkOptions.l1:
          await switchChain(false, false)
          // Don't change tx params when paying relayer fee
          if (!payRelayer) {
            handleTokenChange(NATIVE_CHAIN_TICKER)
            setSendToMyself(false)
          }
          break
        case networkOptions.avax:
          await switchChain(true, true)
          // Don't change tx params when paying relayer fee
          if (!payRelayer) {
            handleTokenChange(AVAX_CHAIN_TICKER)
            setSendToMyself(false)
          }
          break
        default:
          break
      }
      setLoadingNetworkSwitch(false)
    } catch (err: any) {
      if (err.message.includes('User rejected the request.')) {
        setNetworkOrigin(oldNetwork)
        setLoadingNetworkSwitch(false)
      }
      console.error('An error occurred:', err)
      Promise.reject(new Error(err.message))
    }
  }, [networkOrigin, oldNetwork, payRelayer])

  // Trigger network change
  useEffect(() => {
    if (networkOrigin !== oldNetwork) {
      networkChange().catch(throwAsync)
    }
  }, [networkOrigin, oldNetwork, throwAsync, networkChange])

  // Listen to wallet chain changes
  useEffect(() => {
    if (localStorage.getItem('Web3Auth-cachedAdapter') === 'metamask') {
      const { ethereum } = window as any
      if (!ethereum) return

      const handleChainChanged = async (chainId: string) => {
        console.log('chainId', chainId)
        // check if new chainId corresponds to the source of current network route
        if (networkOrigin === networkOptions.l1 && chainId === l1NativeConfig.chainIdHex) {
          setLoadingNetworkSwitch(false)
          setOldNetwork(networkOrigin)
        }
        if (networkOrigin === networkOptions.avax && chainId === avalancheConfig.chainIdHex) {
          setLoadingNetworkSwitch(false)
          setOldNetwork(networkOrigin)
        }
        setOldNetwork(chainIdNetwork(chainId))
      }

      ethereum.on('chainChanged', handleChainChanged)

      // eslint-disable-next-line consistent-return
      return () => {
        ethereum.removeListener('chainChanged', handleChainChanged)
      }
    }
  }, [
    networkOrigin,
    oldNetwork,
    l1NativeConfig.chainIdHex,
    avalancheConfig.chainIdHex,
    setLoadingNetworkSwitch,
    setOldNetwork,
  ])

  /* -------------------------------------------------------------------------- */
  /*                       Logic: Process State Variables                       */
  /* -------------------------------------------------------------------------- */

  const handleSendToMyselfToogle = useCallback(() => {
    if (!address) return

    // Given a state variable will not be updated immediately, we need to use the current value to update the remaining fucntion logic
    const currSendToMyself = !sendToMyself
    setSendToMyself(prev => !prev)

    // If send to myself is toggled, set the destination address to the user's address
    if (currSendToMyself) {
      setDestinationAddress({
        value: address,
        touched: true,
        error: '',
      })
      setValidSendAddress(true)
    }
  }, [sendToMyself, address])

  /* -------------------------------------------------------------------------- */
  /*                          Transaction Confirmation                          */
  /* -------------------------------------------------------------------------- */
  const handleTransactionConfirmation = useCallback(() => {
    setTxHash(null)
    setSendingTx(false)
    setAmount({ value: '', touched: false, error: '' })
    setDestinationAddress({ value: '', touched: false, error: '' })
    setHandleCheckStatus({ input: '', message: '', verified: false })
  }, [setTxHash, setSendingTx, setAmount, setDestinationAddress, setHandleCheckStatus])

  return (
    <>
      {address && (
        <>
          <ModalSkeleton
            opened={openedConfirmTx}
            onClose={closeConfirmTx}
            size="420px"
            title={t('components.send.confirmTransaction', 'Confirm Transaction')}
          >
            <ConfirmTransaction close={closeConfirmTx} confirm={confirm} />
          </ModalSkeleton>
          <ModalSkeleton opened={openedError} onClose={closeError} size="420px">
            <Modal.CloseButton onClick={closeError} />
            <ErrorMessage
              message={t('components.send.error', 'Error sending transaction')}
              details={error}
            />
          </ModalSkeleton>
          {sendingTx ? (
            <Stack>
              {txHash ? (
                <OnTransfer
                  hash={txHash}
                  chainId={
                    networkOrigin === networkOptions.avax
                      ? avalancheConfig.chainId
                      : l1NativeConfig.chainId
                  }
                  success={handleTransactionConfirmation}
                  onClose={close}
                  successMessage={t('buttons.sendMore', 'Send More')}
                />
              ) : (
                <Loading title={t('components.send.sendingTx', 'Sending Transaction')} />
              )}
            </Stack>
          ) : (
            <Stack className={classes.tileForm}>
              <Stack style={{ marginTop: 20 }}>
                <Stack style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                  <Title className={classes.headers}>{t('components.send.amount', 'Amount')}</Title>
                  <SendDropdownButton
                    options={networkDropdownOptions}
                    selectedOption={networkOrigin}
                    setSelectedOption={handleNetworkOriginChange}
                    disabled={
                      !(hasBridgeL1 || hasBridgeAvax) ||
                      openedConfirmTx ||
                      openedError ||
                      txParamsLocked
                    }
                    label="Origin"
                  />
                </Stack>
                <CurrencyInput
                  amount={amount}
                  setAmount={setAmount}
                  token={token}
                  setToken={handleTokenChange}
                  estimatedFee={estimatedFee}
                  networkOrigin={networkOrigin}
                  disabledDropdown={openedConfirmTx || openedError || txParamsLocked}
                  disabledInput={openedConfirmTx || openedError || txParamsLocked}
                />
              </Stack>
              <Stack style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                <Title className={classes.headers}>{t('components.send.sentTo', 'Send To')}</Title>
                <SendDropdownButton
                  options={networkDropdownOptions}
                  selectedOption={networkDestination}
                  setSelectedOption={handleNetworkDestinationChange}
                  disabled={
                    !tokenBridgeable ||
                    openedConfirmTx ||
                    openedError ||
                    loadingNetworkSwitch ||
                    txParamsLocked
                  }
                  label="Destination"
                />
              </Stack>
              {withToggleToMyself && (
                <Switch
                  className={classes.switch}
                  checked={sendToMyself}
                  onChange={handleSendToMyselfToogle}
                  label={t('components.send.myself', 'My Account Address')}
                  readOnly={txParamsLocked}
                />
              )}
              {!sendToMyself && (
                <SpecialInputText
                  disabled={txParamsLocked}
                  placeholder={
                    noUsernameAllowed
                      ? t('components.send.placeholderAddress', 'Enter address')
                      : t(
                          'components.send.placeholderAddressAndUsername',
                          'Enter address or username'
                        )
                  }
                  value={destinationAddress.value}
                  error={
                    destinationAddress.touched && destinationAddress.error !== ''
                      ? destinationAddress.error
                      : destinationAddress.touched &&
                          !isAddress(destinationAddress.value) &&
                          noUsernameAllowed
                        ? t(
                            'components.send.networkInputError',
                            'On this network route you can only send to addresses, not usernames'
                          )
                        : null
                  }
                  borderStatus={
                    destinationAddress.touched
                      ? destinationAddress.error
                        ? 'error'
                        : 'success'
                      : 'default'
                  }
                  iconLeft={iconLeft}
                  iconRight={iconRight}
                  onChange={handleDestinationChange}
                  tipLeft={
                    noUsernameAllowed
                      ? t(
                          'components.send.sentToTipAddress',
                          'Enter your recipient’s 0x/wallet address'
                        )
                      : t(
                          'components.send.sentToTipAddressAndUsername',
                          'Enter your recipient’s @username or 0x/wallet address'
                        )
                  }
                />
              )}
              <>
                {payRelayer && readyToSend && (
                  <PayBridgeFee
                    relayerFeePaid={relayerFeePaid}
                    relayerFeeLoading={relayerFeeLoading}
                    classes={classes}
                    networkDestination={networkDestination}
                    sendTransferAvax={sendTransferAvax}
                    setSendingTx={setSendingTx}
                    setError={setError}
                    openError={openError}
                    updateOkToClose={updateOkToClose}
                  />
                )}
                <Stack style={{ flexDirection: 'row' }}>
                  <RootButton
                    style1={false}
                    expand
                    secondary
                    onClick={close}
                    disabled={payRelayer && relayerFeeLoading.current}
                  >
                    {t('buttons.cancel', 'Cancel')}
                  </RootButton>
                  <RootButton
                    style1={false}
                    expand
                    disabled={
                      // if needs to pay relayer then check relayer fee status
                      (payRelayer &&
                        (!relayerFeePaid.current ||
                          relayerFeeLoading.current ||
                          loadingNetworkSwitch)) ||
                      // if not paying relayer then check if ready to send
                      !readyToSend ||
                      // if sending transaction
                      openedConfirmTx ||
                      openedError
                    }
                    onClick={pushTransaction}
                  >
                    {t('buttons.send', 'Send')}
                  </RootButton>
                </Stack>
                {(payRelayer // when paying relayer fee, show while its loading or when network switch is done
                  ? relayerFeeLoading.current || !loadingNetworkSwitch
                  : !loadingNetworkSwitch) && (
                  <TransactionSummary amount={amount.value} crossChain={crossChain} />
                )}
                {(payRelayer // when paying relayer fee, only show network switch after its paid
                  ? relayerFeePaid.current && loadingNetworkSwitch
                  : loadingNetworkSwitch) && (
                  <Stack>
                    <Loading
                      title={t(
                        'components.send.switchingNetwork',
                        'Confirm network switching in your wallet to proceed'
                      )}
                    />
                  </Stack>
                )}
              </>
            </Stack>
          )}
        </>
      )}
    </>
  )
}
export default Send
