import { createContext, ReactNode, useContext } from 'react'

import { EconomicsHook, EmptyEconomics, useEconomics } from '@/hooks/useEconomics'

type WithChildren = {
  children?: ReactNode
}

const EconomicsContext = createContext<EconomicsHook>(EmptyEconomics)

export const EconomicsProvider: React.FC<WithChildren> = ({ children }) => {
  const value = useEconomics()
  return <EconomicsContext.Provider value={value}>{children}</EconomicsContext.Provider>
}

export const useEconomicsContext = () => useContext(EconomicsContext)
