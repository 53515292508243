import { Grid, Stack, Text, Title, useMantineTheme } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { useBalancesContext } from '@/contexts/BalancesContext'
import { useEconomicsContext } from '@/contexts/EconomicsContext'
import { useModalContext } from '@/contexts/ModalContext'

import RootButton from '../Buttons/RootButton'
import TooltipTitle from '../TooltipTitle'
import classes from './RewardsDisplay.module.css'

interface RewardsDisplayProps {
  closePopup?: () => void
}

const RewardsDisplay: React.FC<RewardsDisplayProps> = ({ closePopup }) => {
  const { t } = useTranslation()
  const theme = useMantineTheme()
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.md})`)
  // use modals provider to get send and receive modals
  const { openModal } = useModalContext()
  const { rl1UnlockRate, ll1UnlockRate } = useEconomicsContext()

  const openRL1Modal = useCallback(() => {
    openModal('unwrapRL1')
  }, [openModal])

  const openLL1Modal = useCallback(() => {
    openModal('unwrapLL1')
  }, [openModal])

  const {
    ll1: { withdrawable: ll1Withdrawable, locked: ll1Locked },
    rl1: { hasStaking, withdrawable: rl1Withdrawable, locked: rl1Locked },
  } = useBalancesContext()

  return (
    <Stack style={{ gap: '30px' }}>
      <Title order={isMobile ? 3 : 2}>{t('components.wallet.rewards', 'Rewards')}</Title>
      {!closePopup ? (
        <Stack style={{ gap: '60px', flexDirection: isMobile ? 'column' : 'row' }}>
          {hasStaking && (
            <Stack
              style={{
                gap: '20px',
                width: '330px',
              }}
            >
              <Stack style={{ gap: '5px' }}>
                <Grid
                  justify="flex-start"
                  style={{ gap: '0 10px', justifyContent: 'space-between' }}
                >
                  <Grid.Col span="content">
                    <TooltipTitle
                      title={t('components.wallet.unlockReward', 'Available Rewards L1')}
                      tooltip={t(
                        'components.wallet.unlockRewardTooltip',
                        `The number of Rewards L1 (RL1) tokens you have earned by staking, validating, or supporting the network, are currently unlocked, and can be unwrapped into usable L1 tokens on the LAMINA1 Network.

                Note: The current unlock schedule for RL1 tokens is {{rate}}. Check back here regularly to unwrap more RL1 over time.`,
                        { rate: `${(90 * rl1UnlockRate).toFixed(0)}% per quarter` }
                      )}
                    />
                  </Grid.Col>
                  <Grid.Col span="auto">
                    <Stack style={{ gap: '0px', alignItems: 'end' }}>
                      <Text className={classes.rewardsAmount}>{rl1Withdrawable?.display}</Text>
                    </Stack>
                  </Grid.Col>
                </Grid>
                <Text className={classes.rewardsDiscription}>
                  {rl1Locked.display} RL1 are locked
                </Text>
              </Stack>
              <RootButton
                secondary
                disabled={!rl1Withdrawable?.value}
                expand={!!closePopup}
                onClick={() => {
                  openRL1Modal()
                }}
                style={{
                  alignSelf: 'flex-end',
                }}
              >
                {t('buttons.unwrap', 'Unwrap')} {closePopup && `${rl1Withdrawable?.display} RL1`}
              </RootButton>
            </Stack>
          )}
          <Stack style={{ width: '330px', gap: '20px' }}>
            <Stack style={{ gap: '5px' }}>
              <Grid justify="flex-start" style={{ gap: '0 10px', justifyContent: 'space-between' }}>
                <Grid.Col span="content">
                  <TooltipTitle
                    title={t('components.wallet.unlockLaunch', 'Available Launch L1')}
                    tooltip={t(
                      'components.wallet.unlockLaunchTooltip',
                      ` The number of Launch L1 (LL1) tokens you have earned by participating in early projects, quests, or partnerships, are currently unlocked, and can be unwrapped into usable L1 tokens on the LAMINA1 network.

                Note: The current unlock schedule for LL1 tokens is {{rate}}. Check back here regularly to unwrap more LL1 over time.`,
                      { rate: `${(90 * ll1UnlockRate).toFixed(0)}% per quarter` }
                    )}
                  />
                </Grid.Col>
                <Grid.Col span="auto">
                  <Stack style={{ gap: '0px', alignItems: 'end' }}>
                    <Text className={classes.rewardsAmount}>{ll1Withdrawable?.display}</Text>
                  </Stack>
                </Grid.Col>
              </Grid>
              <Text className={classes.rewardsDiscription}>{ll1Locked.display} LL1 are locked</Text>
            </Stack>
            <RootButton
              secondary
              disabled={!ll1Withdrawable?.value}
              onClick={() => {
                openLL1Modal()
              }}
              style={{
                alignSelf: 'flex-end',
              }}
            >
              {t('buttons.unwrap', 'Unwrap')} {closePopup && `${ll1Withdrawable?.display} LL1`}
            </RootButton>
          </Stack>
        </Stack>
      ) : (
        <Stack style={{ gap: '15px', width: '320px' }}>
          <Stack style={{ flexDirection: 'row' }}>
            <RootButton
              secondary
              disabled={!rl1Withdrawable?.value}
              expand
              onClick={() => {
                closePopup()
                openRL1Modal()
              }}
            >
              {t('buttons.unwrap', 'Unwrap')} {`${rl1Withdrawable?.display} RL1`}
            </RootButton>
          </Stack>
          <Stack style={{ flexDirection: 'row' }}>
            <RootButton
              secondary
              disabled={!ll1Withdrawable?.value}
              expand
              onClick={() => {
                closePopup()
                openLL1Modal()
              }}
            >
              {t('buttons.unwrap', 'Unwrap')} {`${ll1Withdrawable?.display} LL1`}
            </RootButton>
          </Stack>
        </Stack>
      )}
    </Stack>
  )
}
export default RewardsDisplay
