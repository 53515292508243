import { Stack, Text, useMantineTheme } from '@mantine/core'
import React, { useMemo } from 'react'

import { SpecialInputText, SpecialInputTextProps } from './SpecialInputText'

export interface ValidityMessage {
  error: string | JSX.Element
  success: string
  init: string
}

type SpecialInputTileProps = SpecialInputTextProps & {
  label?: string
  rule?: JSX.Element
  validityMessage?: ValidityMessage
  errorMessage?: string | JSX.Element
  success?: boolean
  tip?: string
  ruleDown?: boolean
}

const SpecialInputTile: React.FC<SpecialInputTileProps> = ({
  label,
  rule,
  validityMessage,
  errorMessage,
  tip,
  success,
  ruleDown = true,
  ...props
}) => {
  const theme = useMantineTheme()
  const message = useMemo(() => {
    if (validityMessage?.error) {
      return validityMessage.error
    }
    if (validityMessage?.success) {
      return validityMessage.success
    }
    return validityMessage?.init
  }, [validityMessage])

  return (
    <Stack style={{ flexDirection: 'column', gap: '0' }}>
      <Stack style={{ gap: '3px' }}>
        {!ruleDown && (
          <Stack style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
            {validityMessage ? (
              <Text
                style={{
                  color: validityMessage?.init
                    ? theme.colors.textAndIcon[0]
                    : validityMessage?.success
                      ? theme.colors.l1Primary[0]
                      : theme.colors.notification[0],
                  fontSize: '14px',
                }}
              >
                {message}
              </Text>
            ) : (
              <Text
                style={{
                  color:
                    errorMessage === label
                      ? theme.colors.textAndIcon[0]
                      : success
                        ? theme.colors.l1Primary[0]
                        : theme.colors.notification[0],
                  fontSize: '14px',
                }}
              >
                {errorMessage}
              </Text>
            )}
            {rule && <Text>{rule}</Text>}
          </Stack>
        )}
        <SpecialInputText
          borderStatus={success ? 'success' : 'default'}
          identitySetup
          {...(props as any)}
        />
        {ruleDown && (
          <Stack style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
            {validityMessage ? (
              <Text
                style={{
                  color: validityMessage?.init
                    ? theme.colors.textAndIcon[0]
                    : validityMessage?.success
                      ? theme.colors.l1Primary[0]
                      : theme.colors.notification[0],
                  fontSize: '14px',
                }}
              >
                {message}
              </Text>
            ) : (
              <Text
                style={{
                  color:
                    errorMessage === label
                      ? theme.colors.textAndIcon[0]
                      : success
                        ? theme.colors.l1Primary[0]
                        : theme.colors.notification[0],
                  fontSize: '14px',
                }}
              >
                {errorMessage}
              </Text>
            )}
            <Text>{rule}</Text>
          </Stack>
        )}
      </Stack>
      {tip && (
        <Text size="sm" style={{ color: theme.colors.paragraph[0] }}>
          {tip}
        </Text>
      )}
    </Stack>
  )
}

export default SpecialInputTile
