import { useEffect, useState } from 'react'
import { useBalance } from 'wagmi'

import { emptyBalance, processBalance, ProcessedBalance } from '@/utils/utils'

const useCustomBalance = (
  chainId: number,
  address: `0x${string}`,
  blockNumber: number,
  enabled: boolean = true
) => {
  // Get balance
  const { data, isLoading, isError, error, refetch } = useBalance({
    chainId,
    address,
  })

  // Set value
  const [value, setValue] = useState<ProcessedBalance>(emptyBalance)
  useEffect(() => {
    if (!isLoading && !isError && data !== undefined) {
      setValue(processBalance(data.value))
    }
  }, [data, isLoading, isError])

  // Refetch
  // NOTE: need 2 separate useEffects because data changes after refetch is called
  // and we only want to call refetch when enabled flag changes or on new blocks
  useEffect(() => {
    if (enabled) {
      refetch()
    }
  }, [enabled, blockNumber])

  return {
    value,
    isLoading,
    error: { call: `customBalance`, msg: error?.message, isError: enabled ? isError : false },
  }
}

export default useCustomBalance
