import { Stack, Text, Title, useMantineTheme } from '@mantine/core'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import RootButton from '@/components/Buttons/RootButton'
import { ConfirmationLoading, ConfirmationReady } from '@/components/Identity/ConfirmModals/utils'
import TransactionSummary from '@/components/Transfer/TransactionSummary'
import { LL1_ADDRESS, RL1_ADDRESS } from '@/constants/blockchain'
import LL1 from '@/contracts/LL1'
import RL1 from '@/contracts/RL1'
import { ContractParams, useChainFunctions } from '@/hooks/useChain'
import { l1NativeWagmiConfig } from '@/plugins/auth/config'
import { ProcessedBalance } from '@/utils/utils'

import ModalSkeleton from '../ModalSkeleton'

interface UnwrapModalProps {
  unwrap: 'RL1' | 'LL1'
  amount?: ProcessedBalance
  close: () => void
}

const UnwrapModal: React.FC<UnwrapModalProps> = ({
  unwrap,
  amount = {
    value: BigInt(0),
    display: '0',
  },
  close,
}) => {
  const { t } = useTranslation()
  const theme = useMantineTheme()
  const [loading, setLoading] = useState<boolean>(false)
  const [isReady, setIsReady] = useState<boolean>(false)

  const { writeContract } = useChainFunctions(l1NativeWagmiConfig)

  const callConfig: ContractParams | undefined = useMemo(() => {
    if (!amount.value) {
      return undefined
    }
    return {
      address: unwrap === 'RL1' ? RL1_ADDRESS : LL1_ADDRESS,
      abi: unwrap === 'RL1' ? RL1 : LL1,
      functionName: 'withdraw',
    }
  }, [amount])

  const title = useMemo(
    () =>
      unwrap === 'RL1'
        ? t('components.wallet.unwrapRl1', 'Unwrap Rewards L1')
        : t('components.wallet.unwrapLl1', 'Unwrap Launch L1'),
    [unwrap]
  )

  const handleUnwrapModal = useCallback(async () => {
    if (!amount.value || !callConfig) return
    try {
      setLoading(true)
      await writeContract(callConfig)
      setLoading(false)
      setIsReady(true)
    } catch (error) {
      console.error(error)
    }
    setLoading(false)
  }, [callConfig, writeContract])

  return (
    <ModalSkeleton opened onClose={close} title={title}>
      {isReady && (
        <ConfirmationReady
          message={t('components.wallet.modal.unwrap.readyMessage', 'Unwrap Submitted')}
          tip={t(
            'components.wallet.modal.unwrap.tipMessage',
            'Check Wallet History for Transaction Status'
          )}
          success={close}
        />
      )}
      {loading && (
        <ConfirmationLoading
          title={t('components.wallet.modal.unwrap.loadingMessage', 'Unwrapping...')}
        />
      )}
      {!isReady && !loading && (
        <Stack>
          <Text style={{ marginBottom: '20px' }}>
            {unwrap === 'RL1'
              ? t(
                  'components.wallet.modal.unwrap.infoRL1',
                  'Unwrap available Rewards L1  tokens to turn them into usable L1 tokens.'
                )
              : t(
                  'components.wallet.modal.unwrap.infoLL1',
                  'Unwrap available Launch L1  tokens to turn them into usable L1 tokens.'
                )}
          </Text>
          <Title order={4}>
            {unwrap === 'RL1'
              ? t('components.wallet.modal.unwrap.titleRL1', 'Available Rewards L1')
              : t('components.wallet.modal.unwrap.titleLL1', 'Available Launch L1')}
          </Title>
          <Stack
            style={{
              backgroundColor: theme.colors.background3[0],
              borderRadius: '12px',
              padding: '16px',
              width: 'fit-content',
              gap: 20,
            }}
          >
            <Title order={4}>
              {amount?.display} {unwrap}
            </Title>
          </Stack>
          <Stack style={{ flexDirection: 'row', marginTop: '20px' }}>
            <RootButton onClick={close} style1={false} secondary expand>
              {t('buttons.cancel', 'Cancel')}
            </RootButton>
            <RootButton onClick={handleUnwrapModal} style1={false} expand disabled={!amount.value}>
              {t('buttons.unwrap', 'Unwrap')}
            </RootButton>
          </Stack>
          <TransactionSummary callConfig={callConfig} />
        </Stack>
      )}
    </ModalSkeleton>
  )
}
export default UnwrapModal
