import { useCallback } from 'react'
import { erc20Abi, parseEther, TransactionReceipt, zeroAddress } from 'viem'

import {
  AVAX_CHAIN_BLOCKCHAIN_ID,
  BRIDGE_AVAX_ERC20_REMOTE_ADDRESS,
  BRIDGE_AVAX_NATIVE_HOME_ADDRESS,
  BRIDGE_L1_ERC20_REMOTE_ADDRESS,
  BRIDGE_L1_NATIVE_HOME_ADDRESS,
  LAMINA1_CHAIN_BLOCKCHAIN_ID,
} from '@/constants/blockchain'
import IERC20TokenTransferrer from '@/contracts/IERC20TokenTransferrer'
import INativeTokenTransferrer from '@/contracts/INativeTokenTransferrer'
import { useChainFunctions } from '@/hooks/useChain'
import { useAuth } from '@/plugins/auth'
import { avalancheWagmiConfig, l1NativeWagmiConfig } from '@/plugins/auth/config'

const useBridge = () => {
  const { address } = useAuth()

  const { readContract, writeContract } = useChainFunctions(l1NativeWagmiConfig)
  const { readContract: readContractAvax, writeContract: writeContractAvax } =
    useChainFunctions(avalancheWagmiConfig)

  // L1 native (l1 subnet) -> L1 ERC20 (avax C)
  const nativeL1ToAvax = useCallback(
    async (destination: string, amount: string): Promise<string | null> => {
      try {
        const receipt = (await writeContract({
          address: BRIDGE_L1_NATIVE_HOME_ADDRESS as `0x${string}`,
          abi: INativeTokenTransferrer,
          functionName: 'send',
          args: [
            {
              destinationBlockchainID: AVAX_CHAIN_BLOCKCHAIN_ID,
              destinationTokenTransferrerAddress: BRIDGE_L1_ERC20_REMOTE_ADDRESS,
              recipient: destination as `0x${string}`,
              // 0 for now, later fee will be paid in AVAX ERC20 on L1 Subnet
              primaryFeeTokenAddress: zeroAddress,
              primaryFee: BigInt(0),
              secondaryFee: BigInt(0),
              requiredGasLimit: 100000,
              multiHopFallback: zeroAddress,
            },
          ],
          value: parseEther(amount),
        })) as TransactionReceipt
        if (receipt?.status !== 'success') {
          throw new Error('Transaction failed')
        }
        return receipt?.transactionHash
      } catch (error: any) {
        console.error('Error:', error)
        throw new Error(`Failed to Send transaction: ${error.message}`)
      }
    },
    [writeContract]
  )

  // AVAX ERC20 (l1 subnet) -> AVAX native (avax C)
  const erc20AvaxToAvax = useCallback(
    async (destination: string, amount: string): Promise<string | null> => {
      try {
        // Detect if approval is needed
        const allowance = await readContract({
          address: BRIDGE_AVAX_ERC20_REMOTE_ADDRESS as `0x${string}`,
          abi: erc20Abi,
          functionName: 'allowance',
          args: [address as `0x${string}`, BRIDGE_AVAX_ERC20_REMOTE_ADDRESS as `0x${string}`],
        })
        if ((allowance as bigint) < parseEther(amount)) {
          // Approve contract to spend tokens
          const spendingAmount = '1000000000' // Arbitrary large number for approval (1B tokens)
          await writeContract({
            address: BRIDGE_AVAX_ERC20_REMOTE_ADDRESS as `0x${string}`,
            abi: erc20Abi,
            functionName: 'approve',
            args: [BRIDGE_AVAX_ERC20_REMOTE_ADDRESS as `0x${string}`, parseEther(spendingAmount)],
          })
        }

        const receipt = (await writeContract({
          address: BRIDGE_AVAX_ERC20_REMOTE_ADDRESS as `0x${string}`,
          abi: IERC20TokenTransferrer,
          functionName: 'send',
          args: [
            {
              destinationBlockchainID: AVAX_CHAIN_BLOCKCHAIN_ID,
              destinationTokenTransferrerAddress: BRIDGE_AVAX_NATIVE_HOME_ADDRESS,
              recipient: destination as `0x${string}`,
              // 0 for now, later fee will be paid in AVAX ERC20 on L1 Subnet
              primaryFeeTokenAddress: zeroAddress,
              primaryFee: BigInt(0),
              secondaryFee: BigInt(0),
              requiredGasLimit: 100000,
              multiHopFallback: zeroAddress,
            },
            parseEther(amount),
          ],
        })) as TransactionReceipt
        if (receipt?.status !== 'success') {
          throw new Error('Transaction failed')
        }
        return receipt?.transactionHash
      } catch (error: any) {
        console.error('Error:', error)
        throw new Error(`Failed to Send transaction: ${error.message}`)
      }
    },
    [address, readContract, writeContract]
  )

  // L1 ERC20 (avax C) -> L1 native (l1 subnet)
  const erc20L1ToLamina1 = useCallback(
    async (destination: string, amount: string): Promise<string | null> => {
      try {
        // Detect if approval is needed
        const allowance = await readContractAvax({
          address: BRIDGE_L1_ERC20_REMOTE_ADDRESS as `0x${string}`,
          abi: erc20Abi,
          functionName: 'allowance',
          args: [address as `0x${string}`, BRIDGE_L1_ERC20_REMOTE_ADDRESS as `0x${string}`],
        })
        if ((allowance as bigint) < parseEther(amount)) {
          // Approve contract to spend tokens
          const spendingAmount = '1000000000' // Arbitrary large number for approval (1B tokens)
          await writeContractAvax({
            address: BRIDGE_L1_ERC20_REMOTE_ADDRESS as `0x${string}`,
            abi: erc20Abi,
            functionName: 'approve',
            args: [BRIDGE_L1_ERC20_REMOTE_ADDRESS as `0x${string}`, parseEther(spendingAmount)],
          })
        }

        const receipt = (await writeContractAvax({
          address: BRIDGE_L1_ERC20_REMOTE_ADDRESS as `0x${string}`,
          abi: IERC20TokenTransferrer,
          functionName: 'send',
          args: [
            {
              destinationBlockchainID: LAMINA1_CHAIN_BLOCKCHAIN_ID,
              destinationTokenTransferrerAddress: BRIDGE_L1_NATIVE_HOME_ADDRESS,
              recipient: destination as `0x${string}`,
              // 0 for now, later fee will be paid in L1 ERC20 on AVAX C chain
              primaryFeeTokenAddress: zeroAddress,
              primaryFee: BigInt(0),
              secondaryFee: BigInt(0),
              requiredGasLimit: 100000,
              multiHopFallback: zeroAddress,
            },
            parseEther(amount),
          ],
        })) as TransactionReceipt
        if (receipt?.status !== 'success') {
          throw new Error('Transaction failed')
        }
        return receipt?.transactionHash
      } catch (error: any) {
        console.error('Error:', error)
        throw new Error(`Failed to Send transaction: ${error.message}`)
      }
    },
    [address, readContractAvax, writeContractAvax]
  )

  // AVAX native (avax C) -> AVAX ERC20 (l1 subnet)
  const nativeAvaxToLamina1 = useCallback(
    async (destination: string, amount: string): Promise<string | null> => {
      try {
        const receipt = (await writeContractAvax({
          address: BRIDGE_AVAX_NATIVE_HOME_ADDRESS as `0x${string}`,
          abi: INativeTokenTransferrer,
          functionName: 'send',
          args: [
            {
              destinationBlockchainID: LAMINA1_CHAIN_BLOCKCHAIN_ID,
              destinationTokenTransferrerAddress: BRIDGE_AVAX_ERC20_REMOTE_ADDRESS,
              recipient: destination as `0x${string}`,
              // 0 for now, later fee will be paid in L1 ERC20 on AVAX C chain
              primaryFeeTokenAddress: zeroAddress,
              primaryFee: BigInt(0),
              secondaryFee: BigInt(0),
              requiredGasLimit: 100000,
              multiHopFallback: zeroAddress,
            },
          ],
          value: parseEther(amount),
        })) as TransactionReceipt
        if (receipt?.status !== 'success') {
          throw new Error('Transaction failed')
        }
        return receipt?.transactionHash
      } catch (error: any) {
        console.error('Error:', error)
        throw new Error(`Failed to Send transaction: ${error.message}`)
      }
    },
    [writeContractAvax]
  )

  return {
    nativeL1ToAvax,
    erc20AvaxToAvax,
    erc20L1ToLamina1,
    nativeAvaxToLamina1,
  }
}

export default useBridge
