import { useMemo } from 'react'

import { REWARDS_ADDRESS, REWARDS_RECIPIENTS_LIST } from '@/constants/blockchain'
import Rewards from '@/contracts/Rewards'
import { useCustomContractRead } from '@/hooks/useCustomContract'
import { l1NativeWagmiConfig } from '@/plugins/auth/config'
import { parseError } from '@/utils/helpers'

import { useChainValues } from './useChain'

export type RewardRecipient = {
  address: `0x${string}`
  balance: bigint
  percentage: number
}

const useRewards = () => {
  // Get block number
  const { blockNumber } = useChainValues(l1NativeWagmiConfig.id)

  // Slots
  const {
    value: slots,
    isLoading: isLoadingSlots,
    error: errorSlots,
  } = useCustomContractRead(
    {
      chainId: l1NativeWagmiConfig.id,
      address: REWARDS_ADDRESS,
      args: [],
      abi: Rewards,
      functionName: 'getSlots',
    },
    blockNumber
  )

  // Compute percentages of rewards for each of the known recipients
  const rewardsPercentages = useMemo(() => {
    if (slots && slots.length > 0) {
      return REWARDS_RECIPIENTS_LIST.map(recp => {
        const recipientIndex = slots.findIndex(
          ({ recipient }) => recipient.toLowerCase() === recp.toLowerCase()
        )
        if (recipientIndex >= 0) {
          return Number(slots[recipientIndex].score) / 100000
        }
        return 0
      })
    }
    return []
  }, [slots])

  // Loading
  const isLoading = useMemo(() => isLoadingSlots, [isLoadingSlots])

  // Error
  const errors = [errorSlots]
  const error = useMemo(() => parseError(errors), errors)

  return {
    rewardsPercentages,
    isLoading,
    error: {
      call: `rewards.${error.call}`,
      msg: error.msg,
      isError: error.isError,
    },
  }
}

export default useRewards
